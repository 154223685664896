<template>
  <fmh-robo-card class="robocalc" id="robo-advisor">
    <v-card-title class="text-h5 font-weight-medium ml-2 pt-5"
      >Robo-Advisor-Vergleich
    </v-card-title>
    <v-card-subtitle class="white--text text-subtitle-2 ml-2 pb-0 mb-0">
      Ein Robo-Advisor legt Ihr Geld für Sie in Wertpapieren an. Finden Sie den
      richtigen Anbieter für Ihren Anlagewunsch.
    </v-card-subtitle>
    <v-container fluid grid-list-md class="pt-0 px-4">
      <v-row>
        <v-col cols="12">
          <!-- First row -->
          <v-row>
            <v-col md="12" class="pb-1">
              <fmh-card-section
                raised
                :disabled="enabledSections.includes(1) ? false : true"
              >
                <template #name>1. Anlageart</template>
                <template #description
                  >Wie möchten Sie Ihr Geld mit dem Robo-Advisor anlegen? Als
                  ...
                </template>
                <template #body>
                  <v-col md="10" offset-md="1">
                    <fmh-card-button
                      v-for="(asset, index) in assetTypes"
                      :key="index"
                      :color="asset.value == assetType ? 'orange' : ''"
                      :class="
                        asset.value == assetType
                          ? 'white--text mt-2'
                          : 'blue--text text--darken-4 mt-2'
                      "
                      :block="windowWidth < 600"
                      elevation="2"
                      @click="setAssetType(asset.value)"
                    >
                      {{ asset.title }}
                    </fmh-card-button>
                  </v-col>
                </template>
                <template #footer
                  >In einem Entnahmeplan wird ein Einmalbetrag angelegt, der
                  dann in vereinbarten monatlichen Raten ausbezahlt wird. Ideal
                  für die private Altersvorsorge.
                </template>
              </fmh-card-section>
            </v-col>
          </v-row>

          <!-- Second row -->
          <v-row id="robo-advisor-amount">
            <v-col md="12" class="pb-1">
              <fmh-card-section
                raised
                :disabled="enabledSections.includes(2) ? false : true"
              >
                <template #name>2. Betrag</template>
                <template #body>
                  <v-row class="mt-0">
                    <v-col
                      md="4"
                      sm="4"
                      cols="1"
                      class="pb-0"
                      v-if="assetType !== 4"
                    />
                    <v-col md="2" sm="2" cols="0" class="pb-0" v-else></v-col>
                    <v-col
                      md="3"
                      sm="3"
                      cols="6"
                      class="pb-0"
                      v-if="assetType !== 2"
                    >
                      <!-- Einmalige Bertrag -->
                      <fmh-currency-value-input
                        v-if="assetType === 1 || assetType === 4"
                        v-model="investmentAmount"
                        placeholder="einmalig"
                        suffix="EUR"
                        type="number"
                        outlined
                      />
                      <!-- Entnahmeplan -->
                      <fmh-currency-value-input
                        v-if="assetType === 3"
                        v-model="withdrawalPlan"
                        placeholder="einmalig"
                        suffix="EUR"
                        type="number"
                        outlined
                      />
                    </v-col>
                    <v-col
                      class="pb-0"
                      v-if="assetType === 2 || assetType === 4"
                      md="3"
                      sm="3"
                      cols="6"
                    >
                      <!-- Sparplan -->
                      <fmh-currency-value-input
                        v-model="savingsPlan"
                        placeholder="monatlich"
                        suffix="EUR"
                        type="number"
                        outlined
                      />
                    </v-col>

                    <v-col md="3" sm="3" class="ml-0 pl-0 pb-0" cols="4">
                      <fmh-card-button
                        class="blue--text text--darken-4"
                        block
                        @click="confirmMoney"
                      >
                        Weiter
                      </fmh-card-button>
                    </v-col>
                  </v-row>
                </template>
                <template #description
                  >Welchen Betrag (Euro) möchten Sie anlegen?</template
                >
              </fmh-card-section>
            </v-col>
          </v-row>

          <!-- Third row -->
          <v-row id="robo-advisor-deposit-account">
            <v-col md="12" class="pb-1">
              <fmh-card-section
                raised
                :disabled="enabledSections.includes(3) ? false : true"
              >
                <template #name>3. Depotkonto</template>
                <template #description
                  >Welche Art von Depotkonto soll angelegt werden?</template
                >
                <template #body>
                  <v-col md="10" offset-md="1">
                    <fmh-card-button
                      v-for="(type, index) in depositAccountTypes"
                      :key="index"
                      :color="type.value == depositAccountType ? 'orange' : ''"
                      :class="
                        type.value == depositAccountType
                          ? 'white--text mt-2'
                          : 'blue--text text--darken-4 mt-2'
                      "
                      :block="windowWidth < 600"
                      elevation="2"
                      @click="setDepositAccountType(type.value)"
                    >
                      {{ type.title }}
                    </fmh-card-button>
                  </v-col>
                </template>
                <template #footer
                  >Für Robo-Advisor braucht man ein Depotkonto. Dieses wird
                  automatisch bei der jeweiligen Partnerbank angelegt. Möchten
                  Sie das Konto für sich allein einrichten (Einzelkonto), oder
                  benötigen Sie eine dieser besonderen Kontoarten?
                </template>
              </fmh-card-section>
            </v-col>
          </v-row>

          <!-- Fourth row -->
          <v-row id="robo-advisor-investment-focus">
            <v-col md="12" class="pb-1">
              <fmh-card-section
                raised
                :disabled="enabledSections.includes(4) ? false : true"
              >
                <template #name>4. Anlageschwerpunkt</template>
                <template #description
                  >Welche der folgenden Kriterien sind Ihnen wichtig?</template
                >
                <template #body>
                  <v-col md="10" offset-md="1">
                    <fmh-checkbox-button
                      v-for="(type, index) in investmentFocuses"
                      :key="index"
                      :checked="investmentFocus.includes(type.value)"
                      class="mt-2"
                      elevation="0"
                      @click="toggleInvestmentFocus(type.value)"
                    >
                      {{ type.title }}
                    </fmh-checkbox-button>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-sm="4"
                    offset="0"
                    offset-md="4"
                  >
                    <fmh-card-button
                      class="blue--text text--darken-4"
                      block
                      @click="skipStepFour"
                    >
                      Ohne Auswahl weiter
                    </fmh-card-button>
                  </v-col>
                </template>
              </fmh-card-section>
            </v-col>
          </v-row>

          <!-- Fifth row -->
          <v-row id="robo-advisor-system-type">
            <v-col md="12" class="pb-1">
              <fmh-card-section
                raised
                :disabled="enabledSections.includes(5) ? false : true"
              >
                <template #name>5. Anlagetyp</template>
                <template #body>
                  <v-col md="10" offset-md="1">
                    <fmh-card-button
                      v-for="(type, index) in investmentTypes"
                      :key="index"
                      :color="type.value == investmentType ? 'orange' : ''"
                      :class="
                        type.value == investmentType
                          ? 'white--text mt-2'
                          : 'blue--text text--darken-4 mt-2'
                      "
                      :block="windowWidth < 600"
                      elevation="2"
                      @click="setInvestmentType(type.value)"
                    >
                      {{ type.title }}
                    </fmh-card-button>
                  </v-col>
                </template>
                <template #description>Welcher Anlagetyp sind Sie?</template>
                <template #footer
                  >In einer konservativen Anlagestrategie werden maximal 20 %
                  des Portfolios in Aktien investiert. Bei ausgeglichener
                  Strategie maximal 60 %. Und bei einer risikoaffinen Strategie
                  bis zu 100 %. Je höher der Aktienanteil, desto höher das
                  Risiko, aber auch die mögliche Rendite.*</template
                >
              </fmh-card-section>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-0 pt-0 pb-0" id="robo-advisor-calculate">
          <v-row>
            <v-col
              sm="4"
              md="4"
              offset-md="4"
              offset-sm="4"
              class="mt-0 pt-0 mb-1"
            >
              <fmh-card-button
                color="secondary darken-1"
                class="white--text mt-0"
                block
                @click="anaylseRoboAdvisor"
                :disabled="!submitEnabled"
                :loading="loadingRoboAdvisor"
              >
                Berechnen
              </fmh-card-button>
            </v-col>
          </v-row>
          <v-row>
            <v-card-text class="pt-0 text-body-2 text-center mb-0 pb-0">
              <p>
                Der Button „Berechnen“ wird klickbar, sobald alle Angaben
                erfolgt sind. Wenn Sie Ihre Angaben anschließend ändern, klicken
                Sie bitte nochmals „Berechnen“, um ein aktuelles Ergebnis zu
                erhalten.
              </p>
            </v-card-text>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </fmh-robo-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  data() {
    return {
      assetTypes: [
        { title: 'Einmalbetrag', value: 1 },
        { title: 'Sparplan', value: 2 },
        { title: 'Entnahmeplan', value: 3 },
        { title: 'Einmalbetrag und Sparplan', value: 4 }
      ],
      depositAccountTypes: [
        { title: 'Einzelkonto', value: 1 },
        { title: 'Gemeinschaftskonto', value: 2 },
        { title: 'Geschäftskonto', value: 3 },
        { title: 'Kinderkonto', value: 4 }
      ],
      investmentFocuses: [
        { title: 'Nachhaltigkeit', value: 1 },
        { title: 'Performance', value: 16 },
        { title: 'Geringe Gebühren', value: 8 },
        { title: 'Webinare und Events', value: 128 },
        { title: 'Persönliche Beratung', value: 4 },
        { title: 'Viele Anlagestrategien', value: 256 }
        // { title: 'Sicherheit', value: 32 },
        // { title: 'ETF', value: 64 },
      ],
      investmentTypes: [
        { title: 'Konservativ', value: 1 },
        { title: 'Ausgeglichen', value: 2 },
        { title: 'Risikoaffin', value: 3 }
      ],

      enabledSections: [1],
      assetType: 0,

      investmentAmount: '',
      savingsPlan: '',
      withdrawalPlan: '',

      depositAccountType: '',
      investmentType: '',
      investmentFocus: [],
      windowWidth: 1000
    }
  },

  computed: {
    submitEnabled() {
      return this.enabledSections.length > 5
    },
    loadingRoboAdvisor() {
      return this.$store.state.roboAdvisor.loading
    }
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },

  beforeMount() {
    this.windowWidth = window.innerWidth
  },

  methods: {
    // TODO: Refactoring
    ...mapMutations({
      updateInvestmentAmount: 'roboAdvisor/updateInvestmentAmount'
    }),
    ...mapActions({
      calculateRoboAdvisor: 'roboAdvisor/calculateRoboAdvisor'
    }),
    async anaylseRoboAdvisor() {
      let investmentFocus = 0
      this.investmentFocus.map(investment => {
        investmentFocus += investment
      })
      const formData = {
        investmentAmount: this.investmentAmount,
        savingsPlan: this.savingsPlan,
        withdrawalPlan: this.withdrawalPlan,
        assetType: this.assetType,
        depositAccountType: this.depositAccountType,
        investmentType: this.investmentType,
        investmentFocus
      }
      await this.calculateRoboAdvisor(formData)
      this.$store.commit(
        'roboAdvisor/updateInvestmentAmount',
        this.investmentAmount
      )
      this.$store.commit('roboAdvisor/updateInitalState', false)
      this.$store.commit('roboAdvisor/updateSavingsPlan', this.savingsPlan)
      this.$store.commit(
        'roboAdvisor/updateWithdrawalPlan',
        this.withdrawalPlan
      )
      this.$store.commit(
        'roboAdvisor/updateInvestmentStrategies',
        this.investmentType
      )
      this.$store.commit('roboAdvisor/updateAssetTypes', this.assetType)
      setTimeout(() => {
        this.$vuetify.goTo('#robo-advisor-table')
      }, 50)
      // console.log(roboAdvisorApi.set(formData))
    },
    smartphoneScroll(id) {
      const smallDevice = this.$vuetify.breakpoint.smAndDown
      if (smallDevice) {
        this.$vuetify.goTo('#' + id)
      }
    },
    setAssetType(value) {
      this.assetType = value
      if (!this.enabledSections.includes(2)) {
        this.enabledSections.push(2)

        this.smartphoneScroll('robo-advisor-amount')
      }

      // this.$vuetify.goTo('#robo-advisor-amount')
    },

    confirmMoney() {
      if (!this.enabledSections.includes(3)) {
        this.enabledSections.push(3)

        this.smartphoneScroll('robo-advisor-deposit-account')
      }
    },
    skipStepFour() {
      if (!this.enabledSections.includes(5)) {
        this.enabledSections.push(5)

        this.smartphoneScroll('robo-advisor-system-type')
      }
    },

    setDepositAccountType(value) {
      this.depositAccountType = value
      if (!this.enabledSections.includes(4)) {
        this.enabledSections.push(4)

        this.smartphoneScroll('robo-advisor-investment-focus')
      }
    },

    toggleInvestmentFocus(value) {
      if (this.investmentFocus.includes(value)) {
        const focusIndex = this.investmentFocus.indexOf(value)
        this.investmentFocus.splice(focusIndex, 1)
      } else {
        this.investmentFocus.push(value)
      }
      if (this.investmentFocus.length > 0) {
        if (!this.enabledSections.includes(5)) {
          this.enabledSections.push(5)
        }
      } else {
        const sectionIndex = this.enabledSections.indexOf(5)
        if (sectionIndex >= 0) {
          this.enabledSections.splice(sectionIndex, 1)
        }
      }
    },

    setInvestmentType(value) {
      this.investmentType = value
      if (!this.enabledSections.includes(6)) {
        this.enabledSections.push(6)
      }
    }
  }
}
</script>
