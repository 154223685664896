<template>
  <v-card class="px-0 pb-sm-4" flat>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        :order="data.information.order === 'reverse' ? 'last' : 'first'"
      >
        <h3 class="text-h3 pt-3 pb-0 mb-0" :id="getId">
          {{ data.information.title }}
        </h3>
        <p
          class="text-body-2"
          v-if="data.information.text"
          v-html="data.information.text"
        />
        <div v-if="data.information.advantages">
          <v-col
            v-for="(advantage, index) in data.information.advantages"
            :key="index"
            cols="12"
            class="py-0 pb-1"
          >
            <v-row>
              <v-col class="flex-grow-0 py-1 pr-0">
                <v-icon size="24" color="secondary lighten-1">mdi-check</v-icon>
              </v-col>
              <v-col class="py-1 flex-grow-1"> {{ advantage }}</v-col>
            </v-row>
          </v-col>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        :order="$vuetify.breakpoint.smAndDown ? 'last' : 'first'"
      >
        <v-card flat rounded="0" class="mt-sm-2 mt-0 mb-2">
          <v-img
            :aspect-ratio="16 / 9"
            :src="data.information.image"
            :alt="data.information.alt || data.information.title"
            :title="data.information.altTitle || data.information.title"
          >
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    }
  },
  computed: {
    getId() {
      const id = this.data.information.id
      if (id) return id
      return ''
    }
  }
}
</script>
