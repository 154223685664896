<template>
  <v-card flat>
    <v-row class="grey-background--hover">
      <v-col cols="12" class="py-0">
        <div class="primary--text mb-1 text-subtitle-1 d-none d-md-block">
          {{ data.title }}
        </div>
      </v-col>
      <v-col cols="12" md="6" class="pt-0 pr-1">
        <v-img
          class="align-end align-md-start"
          :aspect-ratio="16 / 9"
          :src="data.image"
          :alt="data.title"
        >
          <v-card
            tile
            flat
            class="caption grey-headline text-subtitle-2 text-uppercase px-1"
            width="max-content"
          >
            {{ data.subtitle }}
          </v-card>
        </v-img>
      </v-col>
      <v-col cols="12" md="6" class="pt-0">
        <div class="primary--text text-subtitle-1 d-md-none">
          {{ data.title }}
        </div>
        <div>
          {{ data.text }}
          <FmhLink :link="data.link" link-class="navigation__link--primary"
            >... Mehr</FmhLink
          >
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    }
  }
}
</script>

<style scoped>
.small-article-preview__image {
  float: left;
  margin-right: 10px;
  width: 100%;
}
</style>
