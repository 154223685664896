<template>
  <v-row>
    <v-col cols="2">Anbieter</v-col>
    <v-col cols="2">Anzahl Strategien</v-col>
    <v-col cols="2">Servicegebühr</v-col>
    <v-col cols="2">Produktkosten</v-col>
    <v-col cols="2">Performance</v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ResultTableHeaderRow'
}
</script>
