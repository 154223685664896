import { Header } from './navigation'
import { User } from './user'

export default function(inject) {
  function http() {
    const that = this
    const header = new Header(that)
    const user = new User(that)
    return { header, user }
  }
  inject('http', http)
}
