<template>
  <div>
    <v-tabs v-model="currentTab" light hide-slider show-arrows>
      <v-tab key="2" @click="tabClicked(2)">Details</v-tab>
      <v-tab key="3" @click="tabClicked(3)">Strategie</v-tab>
      <v-tab key="4" @click="tabClicked(4)">Activity</v-tab>
      <v-tab v-if="hasAuszeichnungen" key="15" @click="tabClicked(15)"
        >Auszeichnungen</v-tab
      >
      <v-tab key="11" @click="tabClicked(11)"
        >Mehr über {{ resultItem.anbieter.anbietername }}</v-tab
      >

      <v-tab-item transition="false">
        <v-container>
          <v-row>
            <v-col>
              <strong>Mindestanlage:</strong>
            </v-col>
            <v-col>
              {{ anlagebetrag(resultItem.produktDetails.mindestanlage) }}
            </v-col>
            <v-col>
              <strong>Strategie wechseln:</strong>
            </v-col>
            <v-col>
              <template
                v-if="
                  resultItem.produktDetails.risikoprofilAenderungNichtMoeglich
                "
              >
                Nicht möglich
              </template>
              <template v-else>
                <template
                  v-if="
                    resultItem.produktDetails.risikoprofilAenderungGebuehr == 0
                  "
                >
                </template>
                {{
                  prozent(
                    resultItem.produktDetails.risikoprofilAenderungGebuehr,
                    2
                  )
                }}
              </template>
              <template>
                Kostenfrei
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong>Gebühren:</strong>
            </v-col>
            <v-col>
              <template
                v-for="(gebuehr, index) in resultItem.produktDetails.gebuehren"
              >
                ab {{ anlagebetrag(gebuehr.abDepotwert) }} =
                {{ prozent(gebuehr.gebuehr, 2) }}
                <br :key="index" />
              </template>
            </v-col>
            <v-col>
              <strong>Perfomancegebühr:</strong>
            </v-col>
            <v-col>
              <template v-if="resultItem.produktDetails.performanceGebuehr > 0">
                {{ prozent(resultItem.produktDetails.performanceGebuehr, 2) }}
              </template>
              <template v-else>
                Bei diesem Anbieter fällt keine Performancegebühr an.
              </template>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>

          <template
            v-if="resultItem.produktDetails.sonderaktionBemerkung != ''"
          >
            <div class="text-subtitle-2 text-center">
              Prämien
            </div>
            <v-row>
              <v-col>
                <p>
                  {{ resultItem.produktDetails.sonderaktionBemerkung }}
                </p>
              </v-col>
            </v-row>
          </template>

          <div class="text-subtitle-2 text-center">
            Besonderheit
          </div>
          <div class="text-center">
            <template v-if="resultItem.produktDetails.altersvorsorgePortfolio">
              <strong>{{ resultItem.anbieter.anbietername }}</strong> bietet ein
              Altersvorsorge-Portfolio an.
            </template>
            <template v-else>
              <strong>{{ resultItem.anbieter.anbietername }}</strong> bietet
              kein Altersvorsorge-Portfolio an.
            </template>
          </div>
        </v-container>
      </v-tab-item>

      <v-tab-item transition="false">
        <div class="text-subtitle-2 text-uppercase text-center">
          Anlage
        </div>
        <v-row>
          <v-col>
            <strong>Selbsteinschätzung:</strong>
          </v-col>
          <v-col>
            {{ anbieterDetailsSelbsteinschaetzung(formParam.anlagestrategie) }}
          </v-col>
          <v-col>
            <strong>Portfolio:</strong>
          </v-col>
          <v-col>
            {{ resultItem.produktDetails.anlagestrategiename }}
          </v-col>
        </v-row>

        <div class="text-subtitle-2 text-uppercase text-center">
          Asset Allocation
        </div>
        <v-row>
          <v-col>
            Grafik Asset Allocation
            <div>
              <!-- <template
                v-for="(assetAllocationKlasse, key) in resultItem.produktDetails
                  .assetAllocationKlassen"
                :key="key"
              >
                <v-row>
                  <v-col>
                    <strong>{{ assetAllocationKlasse.bezeichnung }}</strong>
                  </v-col>
                  <v-col>
                    <strong>{{
                      prozent(assetAllocationKlasse.gewichtung, 0)
                    }}</strong>
                  </v-col>
                </v-row>
              </template> -->
            </div>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <strong>Strategien:</strong>
              </v-col>
              <v-col>
                {{ resultItem.produktDetails.anzahlStrategien }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <strong>Strategien mit ESG:</strong>
              </v-col>
              <v-col>
                {{ resultItem.produktDetails.anzahlStrategienMitEsg }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div class="text-subtitle-2 text-uppercase text-center">
          Anlageklassen
        </div>
        <v-row>
          <v-col class="text-center">
            <CheckedText text="ETF" :checked="resultItem.produktpalette.etf" />
          </v-col>
          <v-col class="text-center">
            <CheckedText text="ETC" :checked="resultItem.produktpalette.etc" />
          </v-col>
          <v-col class="text-center">
            <CheckedText
              text="nachhaltige Anlage"
              :checked="resultItem.produktpalette.nachhaltigeAnlagen"
            />
          </v-col>
          <v-col class="text-center">
            <CheckedText
              text="Aktien"
              :checked="resultItem.produktpalette.aktien"
            />
          </v-col>
          <v-col class="text-center">
            <CheckedText
              text="Optionsscheine"
              :checked="resultItem.produktpalette.optionsscheine"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <CheckedText
              text="gemanagte Fonds"
              :checked="resultItem.produktpalette.fonds"
            />
          </v-col>
          <v-col class="text-center">
            <CheckedText
              text="Anleihen"
              :checked="resultItem.produktpalette.anleihen"
            />
          </v-col>
          <v-col class="text-center">
            <CheckedText
              text="Liquidität"
              :checked="resultItem.produktpalette.liquiditaet"
            />
          </v-col>
          <v-col class="text-center">
            <CheckedText
              text="Hedgefonds"
              :checked="resultItem.produktpalette.hedgefonds"
            />
          </v-col>
          <v-col class="text-center">
            <CheckedText
              text="Zertifikate"
              :checked="resultItem.produktpalette.zertifikate"
            />
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
      </v-tab-item>

      <v-tab-item transition="false">
        <div class="text-subtitle-2 text-uppercase text-center">
          Kontakt & Interaktion
        </div>
        <v-row>
          <v-col class="text-center">
            <CheckedText
              text="Telefon"
              :checked="resultItem.kundenservice.telefon"
            />
          </v-col>
          <v-col class="text-center">
            <CheckedText
              text="E-Mail"
              :checked="resultItem.kundenservice.email"
            />
          </v-col>
          <v-col class="text-center">
            <CheckedText text="Chat" :checked="resultItem.kundenservice.chat" />
          </v-col>
          <v-col class="text-center">
            <CheckedText
              text="Social Media"
              :checked="resultItem.kundenservice.socialMedia"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
          <v-col class="text-center">
            <CheckedText
              text="Mobile App"
              :checked="resultItem.kundenservice.mobileApp"
            />
          </v-col>
          <v-col class="text-center">
            <CheckedText
              text="Events"
              :checked="resultItem.kundenservice.events"
            />
          </v-col>
          <v-col> </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <div class="text-subtitle-2 text-uppercase text-center">Betreuung</div>
        <v-row>
          <v-col> </v-col>
          <v-col class="text-center">
            <CheckedText
              text="Persönlicher Ansprechpartner"
              :checked="resultItem.kundenservice.persoenlicherAnsprechpartner"
            />
          </v-col>
          <v-col> </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <div class="text-subtitle-2 text-uppercase text-center">
          Informationen
        </div>
        <v-row>
          <v-col> </v-col>
          <v-col class="text-center">
            <span>
              {{
                anbieterDetailsNewsletterversand(
                  resultItem.kundenservice.newsletterversand
                )
              }}
            </span>
          </v-col>
          <v-col class="text-center">
            <span>
              {{ anbieterDetailsReporting(resultItem.kundenservice.reporting) }}
            </span>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item v-if="hasAuszeichnungen" transition="false">
        <template>
          <div class="text-subtitle-2">Aktuelle Auszeichnungen</div>
          <p>
            Auszeichnungen der FMH-Finanzberatung sind ein klares Zeichen für
            nachweisliche Anbieterqualität. Sie basieren auf
            unabhängigerDatenauswertung und sind ausschließlich für einen kurzen
            Zeitraum gültig. Auf die folgenden Auszeichnungen des Anbietersist
            Verlass – sie sind aktuell und FMH-geprüft.
          </p>
          <div>
            <template
              v-for="auszeichnung in resultItem.anbieter.auszeichnungen"
            >
              <img
                :key="auszeichnung.fmhId"
                :src="auszeichnung.url"
                :alt="auszeichnung.name"
              />
            </template>
          </div>
        </template>
      </v-tab-item>

      <v-tab-item transition="false">
        <v-container>
          <v-row>
            <v-col>
              <strong>Name:</strong>
            </v-col>
            <v-col>
              {{ resultItem.anbieter.anbietername }}
            </v-col>
            <v-col>
              <strong>Gründungsjahr:</strong>
            </v-col>
            <v-col>
              {{ resultItem.anbieter.gruendungsjahr }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong>Sitz:</strong>
            </v-col>
            <v-col>
              {{ resultItem.anbieter.anschrift }}
            </v-col>
            <v-col>
              <strong>Anbietertyp:</strong>
            </v-col>
            <v-col>
              {{ resultItem.anbieter.anbietertyp }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <strong>Mehr über {{ resultItem.anbieter.anbietername }}:</strong>
              <p>
                {{ resultItem.anbieter.beschreibung }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import CheckedText from './CheckedText'
import { mapFields } from 'vuex-map-fields'
import { writeStats } from '../../../api/robo-advisor'
import RoboAdvisor, { settings } from '../../../mixins/RoboAdvisor'

export default {
  mixins: [RoboAdvisor],
  name: 'AnbieterDetails',

  components: {
    CheckedText
  },

  props: {
    resultItem: {
      type: Object,
      default: null
    },
    activeTab: {
      type: Number,
      default: 3
    }
  },

  data() {
    return {
      currentTab: 11
    }
  },

  computed: {
    ...mapFields('param', ['formParam.anlagestrategie']),

    formParam() {
      return this.$store.state.result.resultData.param
    },

    hasAuszeichnungen() {
      return this.resultItem.anbieter.auszeichnungen.length > 0
    }
  },

  methods: {
    anlagebetrag(value, digits) {
      return (
        value.toLocaleString('de-DE', {
          currency: 'EUR',
          minimumFractionDigits: digits
        }) +
        ' ' +
        settings.euroZeichen
      )
    },

    prozent(value, digits) {
      return (
        value.toLocaleString('de-DE', {
          minimumFractionDigits: digits
        }) +
        ' ' +
        settings.prozentZeichen
      )
    },

    anbieterDetailsSelbsteinschaetzung(value) {
      if (value === 0) {
        return 'Keine Angabe'
      } else if (value === 1) {
        return 'Konservativ'
      } else if (value === 2) {
        return 'Ausgeglichen'
      } else if (value === 3) {
        return 'Risikoaffin'
      }
    },

    anbieterDetailsReporting(value) {
      if (value === 0) {
        return 'Monatlicher Reporting'
      } else if (value === 1) {
        return 'Vierteljährlicher Reporting'
      }
    },

    anbieterDetailsNewsletterversand(value) {
      if (value === 0) {
        return 'Kein Newsletterversand'
      } else if (value === 1) {
        return 'Wöchentlicher Newsletterversand'
      } else if (value === 2) {
        return 'Zweiwöchentlicher Newsletterversand'
      } else if (value === 3) {
        return 'Monatlicher Newsletterversand'
      } else if (value === 4) {
        return 'Gelegentlicher Newsletterversand'
      }
    },

    tabClicked(detailsId) {
      // console.log(detailsId);
      writeStats({
        aktionId: 6,
        detailsId,
        anbietername: this.resultItem.anbieter.anbietername
      })
    }
  }
}
</script>
