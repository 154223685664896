var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.resultData,"single-expand":false,"expanded":_vm.expanded,"item-key":"anbieter.anbietername","show-expand":"","hide-default-footer":"","no-data-text":"Keine Daten vorhanden"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.anbieter.anbietername",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"anbieterlink",attrs:{"title":"Weitere Informationen zum Angebot"}},[(item.anbieter.anbieterLogoUrl != '')?[_c('img',{attrs:{"alt":item.anbieter.anbietername,"src":item.anbieter.anbieterLogoUrl}})]:[_vm._v(" "+_vm._s(item.anbieter.anbietername)+" ")]],2)]}},{key:"item.serviceRanking",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.serviceRanking(item.serviceRanking))+" ")]}},{key:"item.performance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prozent(item.performance, 3))+" ")]}},{key:"item.servicegebuehr",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatEuro(item.servicegebuehr, 2))+" ")]}},{key:"item.anlagestrategienRebalancing",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.anlagestrategie(item.produktDetails.anzahlStrategien))+" "),_c('br'),_vm._v(" "+_vm._s(item.produktDetails.rebalancing)+" "),_c('br'),(item.produktDetails.sonderaktionBemerkung != '')?[_vm._v(" Neukunden-Aktion ")]:_vm._e()]}},{key:"item.assetAllocation",fn:function(ref){
var item = ref.item;
return [(item.produktDetails.assetAllocationKlassen.length > 0)?[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-star ")])]:_vm._e()]}},{key:"item.angebotslink",fn:function(ref){
var item = ref.item;
return [(item.anbieter.angebotUrl != '')?[_c('v-btn',{attrs:{"link":"","small":"","href":item.anbieter.angebotUrl,"target":"_blank"},on:{"click":function($event){return _vm.angebotslinkClick(item)}}},[_vm._v(" Anbieter ")])]:_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.tableHeaders.length}},[_c('fmh-anbieter-details',{attrs:{"result-item":item}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }