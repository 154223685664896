import { webApi } from '../../api/robo-advisor'

function defaultState() {
  return {
    resultData: {},
    ts: 0
  }
}

// state
const state = () => defaultState()

// actions
const actions = {
  async loadResultDataAsync({ commit }, param) {
    await webApi.get('schnellcheck', { params: param }).then(response => {
      commit('setResultData', response.data)
    })
  }
}

// mutations
const mutations = {
  setResultData(state, data) {
    state.resultData = data
    state.ts = Date.now()
  },
  resetState(state) {
    // state.chartData = {},
    // state.ts = 0
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
