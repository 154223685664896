<template>
  <div>
    <fmh-input-form></fmh-input-form>
    <fmh-result-table v-if="showResult && hasResultData" />
  </div>
</template>

<script>
export default {
  computed: {
    showResult() {
      return this.$store.state.param.resultParam?.showResult
    },
    hasResultData() {
      return this.$store.state.result.ts !== 0
    }
  }
}
</script>
