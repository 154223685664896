<template>
  <v-row class="white--text">
    <v-col cols="12" md="6" lg="4" style="z-index: 1">
      <h2 class="text-h1">
        <span class="secondary--text text--lighten-1">
          {{ baufi.title.highlight }}
        </span>
        {{ baufi.title.text }}
      </h2>
      <p class="pt-sm-3 hidden-sm-and-down hidden-lg-and-up">
        <FmhLink class="navigation__link--white" :link="demoLink">
          {{ baufi.text.details }}
          <v-icon small color="white"> mdi-play-circle </v-icon>
        </FmhLink>
      </p>
      <p class="pt-3 hidden-sm-and-down">
        <FmhLink class="navigation__link--white" :link="demoLink">
          {{ baufi.text.profit }}
          <v-icon color="white" small> mdi-play-circle </v-icon>
        </FmhLink>
      </p>
    </v-col>
    <v-col cols="12" md="6" lg="4" style="z-index: 1" class="pt-xs-0">
      <span>Immobilienpreis</span>
      <v-select
        v-model="realEstatePriceValue"
        dense
        :items="realEstatePriceItems"
        hide-details
        color="white"
        height="38"
        item-color="secondary"
        item-text="title"
        item-value="value"
        class="mb-3 mt-1"
        solo
      ></v-select>
      <span>Darlehensbetrag</span>
      <v-select
        v-model="loanAmountValue"
        hide-details
        item-text="title"
        item-value="value"
        class="mb-3 mt-1"
        dense
        :items="loanAmountItems"
        solo
      ></v-select>
      <span>Anfangstilgung</span>
      <v-select
        v-model="initialRepaymentValue"
        dense
        class="mb-3 mt-1"
        :items="initialRepaymentItems"
        item-text="title"
        item-value="value"
        hide-details
        solo
      ></v-select>
      <div class="hidden-lg-and-up pt-0">
        <span>Sollzinsbindung</span>
        <v-select
          v-model="yearValue"
          dense
          hide-details
          :items="yearItems"
          item-text="title"
          class="mt-1 mb-5"
          item-value="type"
          solo
        ></v-select>
        <v-btn block color="secondary darken-1" class="mt-4">Berechnen</v-btn>
        <div class="hidden-md-and-up">
          <p class="text-body-2 mt-3">
            <FmhLink class="navigation__link--white" :link="demoLink">
              {{ baufi.text.details }}
              <v-icon color="white" small> mdi-play-circle </v-icon>
            </FmhLink>
          </p>
          <p>
            <FmhLink class="navigation__link--white" :link="demoLink">
              {{ baufi.text.profit }}
              <v-icon color="white" small> mdi-play-circle </v-icon>
            </FmhLink>
          </p>
        </div>
      </div>
    </v-col>
    <v-col cols="4" style="z-index: 1" class="hidden-md-and-down">
      <span>Sollzinsbindung</span>
      <v-row class="px-2 mt-1">
        <v-col
          v-for="(year, yearIndex) in yearItems"
          :key="yearIndex"
          class="px-1 pb-4 pt-0"
          cols="4"
        >
          <v-btn
            block
            height="38"
            :color="year.type === yearValue ? 'secondary darken-1' : 'white'"
            @click="yearValue = year.type"
            >{{ year.title }}</v-btn
          >
        </v-col>
      </v-row>
      <v-btn block color="secondary darken-1 ">Berechnen</v-btn>
      <div class="text-body-2 py-5">
        <!-- Die Detail-Analyse berücksichtig Kfw- Darlehen, Selbstständige,
        Mietobjekte u.ä.  -->
        <FmhLink class="navigation__link--white" :link="demoLink">
          {{ baufi.text.kfw }}
          <v-icon small class="navigation__link--white">
            mdi-play-circle
          </v-icon>
        </FmhLink>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import baufi from '../assets/baufinanizerung.json'
export default {
  data: () => ({
    baufi,
    realEstatePriceItems: [{ title: '500.000 EUR', value: 500000 }],
    loanAmountItems: [{ title: '400.000 EUR', value: 400000 }],
    initialRepaymentItems: [{ title: '3,00%', value: 3 }],
    initialRepaymentValue: 3,
    loanAmountValue: 400000,
    realEstatePriceValue: 500000,
    demoLink: {
      title: 'Alle Informationen',
      preferredPermalink: '/',
      prefetch: 'false'
    },
    yearItems: [
      { title: '5 Jahre', type: '5' },
      { title: '10 Jahre', type: '10' },
      { title: '15 Jahre', type: '15' },
      { title: '20 Jahre', type: '20' },
      { title: '25 Jahre', type: '25' },
      { title: '30 Jahre', type: '30' }
    ],
    yearValue: '10'
  })
}
</script>
