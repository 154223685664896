<template>
  <v-navigation-drawer
    v-model="navigationDrawer"
    color="primary"
    hide-overlay
    fixed
    class="navigation--overlay hidden-md-and-up"
    width="100%"
    :right="true"
  >
    <v-toolbar height="70" color="white">
      <v-row>
        <v-col cols="8" md="5" class="py-0 mt-0">
          <v-img
            contain
            class="navigation__image"
            :src="
              $vuetify.breakpoint.mobile
                ? '/images/fmh_logo.svg'
                : '/images/fmh-zinsen-zahlen-vergleiche.svg'
            "
            alt="FMH Logo"
            position=""
            style="width: 100%; max-width: 400px; height: 45px"
            @click="navigationDrawer = !navigationDrawer"
          ></v-img>
          <!-- <v-img
            contain
            class="navigation__image hidden-sm-and-up"
            src="fmh_logo.svg"
            alt="FMH Logo"
            position=""
            style="width: 100%; max-width: 400px; height: 45px"
            to="/"
            @click="navigationDrawer = !navigationDrawer"
          ></v-img> -->
        </v-col>
        <v-col cols="4" class="py-0">
          <client-only>
            <v-layout class="justify-end">
              <v-btn icon @click.stop="navigationDrawer = !navigationDrawer">
                <v-icon color="primary">mdi-close</v-icon>
              </v-btn>
            </v-layout>
          </client-only>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-expansion-panels
      v-model="navigationPanel"
      flat
      color="primary"
      accordion
    >
      <v-expansion-panel
        v-for="(parentLink, parentIndex) in navigationLinks"
        :key="parentIndex"
        class="py-0 my-0 white--text"
        color="white"
      >
        <v-expansion-panel-header
          expand-icon=""
          :color="
            navigationPanel === parentIndex
              ? 'navigation__expansion-panel'
              : 'primary'
          "
          class="white--text text-body-1 py-0 my-0"
        >
          {{ parentLink.title }}
        </v-expansion-panel-header>

        <v-expansion-panel-content
          :color="
            navigationPanel === parentIndex
              ? 'navigation__expansion-panel'
              : 'primary'
          "
          class="white--text"
        >
          <NavigationLinks
            :parent-link="parentLink"
            :with-parent="true"
          ></NavigationLinks>
        </v-expansion-panel-content>
        <v-divider class="navigation__divider"></v-divider>
      </v-expansion-panel>
    </v-expansion-panels>
    <Search color="white" class="ma-6"></Search>

    <div class="text-center ma-6 mt-10">
      <span v-for="(link, linkIndex) in staticHeaderLinks" :key="linkIndex">
        <FmhLink :link="link" class="navigation__link--white">
          {{ link.title }}
        </FmhLink>

        <span
          v-if="linkIndex < staticHeaderLinks.length - 1"
          class="white--text pa-1"
          style="opacity: 50%"
        >
          |
        </span>
      </span>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      navigationPanel: null,
    }
  },
  computed: {
    navigationLinks() {
      return this.$store.state.navigation.links
    },
    staticHeaderLinks() {
      return this.$store.state.navigation.staticHeaderLinks
    },
    navigationDrawer: {
      get() {
        return this.$store.state.navigation.drawer
      },
      set(data) {
        return this.$store.commit('navigation/updateDrawer', data)
      },
    },
  },
}
</script>
