<template>
  <v-form>
    <v-container class="grey lighten-5">
      <v-overlay :absolute="false" :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <div class="text-subtitle-2">1. Anlageart</div>
      <v-radio-group v-model="anlageart">
        <v-row>
          <v-col>
            <v-radio label="Einmaligen Betrag" :value="1"></v-radio>
          </v-col>
          <v-col>
            <v-radio label="Sparplan" :value="2"></v-radio>
          </v-col>
          <v-col>
            <v-radio label="Entnahmeplan" :value="3"></v-radio>
          </v-col>
          <v-col>
            <v-radio
              label="Einmaligen Betrag und Sparplan"
              :value="4"
            ></v-radio>
          </v-col>
        </v-row>
      </v-radio-group>

      <div class="text-subtitle-2">2. Betrag</div>
      <v-row>
        <v-col>
          <template v-if="anlageart == 1 || anlageart == 4">
            <v-text-field
              v-model.number="anlagebetrag"
              label="Anlagebetrag (einmalig) [EUR]"
              outlined
            >
            </v-text-field>
          </template>
        </v-col>
        <v-col>
          <template v-if="anlageart == 2 || anlageart == 4">
            <v-text-field
              v-model.number="sparplanbetrag"
              label="Sparplan [EUR]"
              outlined
            >
            </v-text-field>
          </template>
        </v-col>
        <v-col>
          <template v-if="anlageart == 3">
            <v-text-field
              v-model.number="entnahmebetrag"
              label="Entnahmeplan [EUR]"
              outlined
            >
            </v-text-field>
          </template>
        </v-col>
        <v-col>
          <v-btn color="primary" block>Weiter</v-btn>
        </v-col>
      </v-row>

      <div class="text-subtitle-2">3. Depotkonto</div>
      <v-radio-group v-model="kontoart">
        <v-row>
          <v-col>
            <v-radio label="Einzelkonto" :value="1"></v-radio>
          </v-col>
          <v-col>
            <v-radio label="Gemeinschaftskonto" :value="2"></v-radio>
          </v-col>
          <v-col>
            <v-radio label="Geschäftskonto" :value="3"></v-radio>
          </v-col>
          <v-col>
            <v-radio label="Kinderkonto" :value="4"></v-radio>
          </v-col>
        </v-row>
      </v-radio-group>

      <div class="text-subtitle-2">4. Anlageschwerpunkte</div>
      <v-row>
        <v-col>
          <v-chip-group
            v-model="anlageschwerpunkte"
            column
            multiple
            active-class="primary--text"
          >
            <v-chip :value="1">Nachhaltigkeit</v-chip>
            <v-chip :value="16">Performance</v-chip>
            <v-chip :value="8">Geringe Gebühren</v-chip>
            <v-chip :value="128">Webinare & Events</v-chip>
            <v-chip :value="4">Persönliche Beratung</v-chip>
            <v-chip :value="256">Viele Anlagestrategien</v-chip>
          </v-chip-group>
        </v-col>
      </v-row>

      <div class="text-subtitle-2">5. Anlagetyp</div>
      <v-radio-group v-model="anlagestrategie">
        <v-row>
          <v-col>
            <v-radio label="Konservativ" :value="1"></v-radio>
          </v-col>
          <v-col>
            <v-radio label="Ausgeglichen" :value="2"></v-radio>
          </v-col>
          <v-col>
            <v-radio label="Risikoaffin" :value="3"></v-radio>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-radio-group>

      <v-row>
        <v-col></v-col>
        <v-col>
          <v-btn color="primary" block @click="submitForm"> Berechnen </v-btn>
        </v-col>
        <v-col>
          <v-btn block @click="resetForm"> Zurücksetzen </v-btn>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
// import writeStats from '@/api/writeStats'

import { writeStats } from '../../../api/robo-advisor'

export default {
  name: 'InputForm',
  data() {
    return {
      isLoading: false,
      rules: {
        anlagebetrag: value => {
          return (
            (value >= 100 && value <= 100000) ||
            'Bitte geben Sie einen Anlagebetrag zwischen XXX und XXX.XXX EUR'
          )
        }
      }
    }
  },

  computed: {
    // anbieterMap() {
    //	return this.$store.state.anbieter.anbieterMap;
    // },
    ...mapFields('param', ['formParam.anlageart']),
    ...mapFields('param', ['formParam.anlagebetrag']),
    //...mapFields('param', ['formParam.dauer']),
    ...mapFields('param', ['formParam.sparplanbetrag']),
    ...mapFields('param', ['formParam.entnahmebetrag']),
    ...mapFields('param', ['formParam.kontoart']),
    ...mapFields('param', ['formParam.anlagestrategie']),
    // ...mapFields("param", ["formParam.produktpalette"]),
    anlageschwerpunkte: {
      get() {
        return this.intToFlagsArray(
          this.$store.state.param.formParam.anlageschwerpunkte
        )
      },
      set(value) {
        const paramValue = this.flagsArrayToInt(value)

        this.$store.commit('param/setFieldValue', {
          path: 'formParam.anlageschwerpunkte',
          value: paramValue
        })
      }
    },
    produktpalette: {
      get() {
        const produktpaletteParam = this.$store.state.param.formParam
          .produktpalette
        const produktpaletteArray = []

        for (let i = 0; i <= 7; i++) {
          if (produktpaletteParam & Math.pow(2, i)) {
            produktpaletteArray.push(Math.pow(2, i))
          }
        }

        return produktpaletteArray
      },
      set(value) {
        let produktpaletteValue = 0
        for (let i = 0; i < value.length; i++) {
          produktpaletteValue = produktpaletteValue + value[i]
        }
        // this.$store.state.param.formParam.produktpalette = produktpaletteValue;
        this.$store.commit('param/setFieldValue', {
          path: 'formParam.produktpalette',
          value: produktpaletteValue
        })
      }
    },
    showResult() {
      return this.$store.state.param.resultParam.showResult
    }
  },

  beforeCreate() {
    this.$store.dispatch('param/loadParam')
  },

  async created() {
    writeStats({ aktionId: 6 })
    if (this.showResult) {
      await this.loadResultData()
    }
  },

  updated() {
    this.$store.dispatch('param/saveParam')
  },

  methods: {
    intToFlagsArray(value) {
      const flagsArray = []

      for (let i = 0; i <= 10; i++) {
        if (value & Math.pow(2, i)) {
          flagsArray.push(Math.pow(2, i))
        }
      }

      return flagsArray
    },
    flagsArrayToInt(flagsArray) {
      let value = 0

      for (let i = 0; i < flagsArray.length; i++) {
        value = value + flagsArray[i]
      }

      return value
    },

    async submitForm() {
      // TODO: Form-Validierung

      await this.loadResultData()

      this.$store.commit('param/setFieldValue', {
        path: 'resultParam.showResult',
        value: true
      })

      writeStats({ aktionId: 8 })
    },
    resetForm() {
      this.$store.commit('param/resetState')
      this.$store.commit('result/resetState')
    },

    showLoader() {
      this.isLoading = true
      setTimeout(() => {
        this.closeLoader()
      }, 10 * 1000)
    },
    closeLoader() {
      this.isLoading = false
    },

    async loadResultData() {
      try {
        // this.showLoader();

        await this.$store.dispatch(
          'result/loadResultDataAsync',
          this.$store.state.param.formParam
        )
      } catch (error) {
        // console.log(error)
      } finally {
        // this.closeLoader();
      }
    }
  }
}
</script>
