import * as roboAdvisorApi from '../../api/robo-advisor'

const state = {
  results: {},
  loading: true,
  investmentAmount: 5000,
  savingsPlan: 0,
  withdrawalPlan: 0,
  assetTypes: 1,
  investmentStrategy: 2,
  initalState: true
}

const mutations = {
  updateResults(state, data) {
    state.results = data
  },
  updateLoading(state, data) {
    state.loading = data
  },
  updateInvestmentAmount(state, data) {
    state.investmentAmount = data
  },
  updateSavingsPlan(state, data) {
    state.savingsPlan = data
  },
  updateWithdrawalPlan(state, data) {
    state.withdrawalPlan = data
  },
  updateAssetTypes(state, data) {
    state.assetTypes = data
  },
  updateInvestmentStrategies(state, data) {
    state.investmentStrategy = data
  },
  updateInitalState(state, data) {
    state.initalState = data
  }
}

const actions = {
  async getResults({ commit }) {
    commit('updateLoading', true)
    const roboAdvisorRawData = await roboAdvisorApi.get()

    const results = JSON.parse(JSON.stringify(roboAdvisorRawData.data))

    commit('updateLoading', false)
    commit('updateResults', results)
  },
  async calculateRoboAdvisor({ commit }, formData) {
    commit('updateLoading', true)
    commit('updateInitalState', false)
    const roboAdvisorRawData = await roboAdvisorApi.set(formData)
    const results = JSON.parse(JSON.stringify(roboAdvisorRawData.data))

    commit('updateLoading', false)
    commit('updateResults', results)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
