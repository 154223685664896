<template>
  <v-row class="mt-0">
    <v-col cols="12" md="2" class="ml-md-3 text-h3 gray--text"
      ><slot name="header"></slot
    ></v-col>
    <v-col cols="12" md="4" class="gray lighten-1  pl-md-0">
      <slot name="first-element"></slot>
    </v-col>
    <slot></slot>
    <v-col cols="12" md="4 " class=" gray lighten-1"
      ><slot name="second-element"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>
