import axios from 'axios'

export const webApi = axios.create({
  baseURL: process.env.VUE_APP_WEBAPI_ROOT,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    ApiKey: process.env.VUE_APP_API_KEY,
    Redaktion: 'FMH'
  }
})

export function writeStats(param) {
  let localParameter = {
    redaktion: '',
    rechnerId: 45,
    aktionId: 0,
    detailsId: 0,
    anbietername: ''
  }
  localParameter = { ...localParameter, ...param }

  webApi.post('fmhstats', localParameter)
}

export function get() {
  //formData
  // let param = {
  //   'param.redaktion': 1,
  //   rechnerId: 45,
  //   aktionId: 0,
  //   detailsId: 0,
  //   anbietername: 0
  // }
  // TODO: Refactoring stored value
  let param = {
    anlageart: 1,
    anlagebetrag: 5000,
    sparplanbetrag: 0,
    entnahmebetrag: 0,
    kontoart: 0,
    produktpalette: 0,
    anlageschwerpunkte: 0,
    anlagestrategie: 2
  }
  return webApi.get('/schnellcheck', { params: param })
}

export function set(formData) {
  //formData
  let param = {
    anlageart: formData.assetType,
    anlagebetrag: formData.investmentAmount,
    sparplanbetrag: formData.savingsPlan,
    entnahmebetrag: formData.withdrawalPlan,
    kontoart: formData.depositAccountType,
    produktpalette: 0,
    anlageschwerpunkte: formData.investmentFocus,
    anlagestrategie: formData.investmentType
  }
  return webApi.get('/schnellcheck', { params: param })
}
