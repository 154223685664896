<template>
  <div>
    <fmh-detail-triple-cols class="pa-3 white-divider">
      <template #header><h3 class="text-h3">Anbieter</h3></template>
      <template #first-element>
        <fmh-detail-list :list="nameAndAddress"></fmh-detail-list>
      </template>
      <template #second-element>
        <fmh-detail-list :list="yearAndType"></fmh-detail-list>
      </template>
    </fmh-detail-triple-cols>
    <fmh-detail-double-cols class="pa-3">
      <template #header><h3 class="text-h3">Profil</h3></template>
      <template #element>
        {{ advisor ? advisor.anbieter.beschreibung : '' }}
      </template>
    </fmh-detail-double-cols>
  </div>
</template>

<script>
export default {
  props: {
    advisor: {
      type: Object,
      default: null
    }
  },
  computed: {
    nameAndAddress() {
      const arr = []
      if (this.advisor) {
        arr.push({
          title: 'Name',
          description: this.advisor.anbieter.anbietername
        })
        arr.push({
          title: 'Anschrift',
          description: this.advisor.anbieter.anschrift
        })
      }
      return arr
    },
    yearAndType() {
      const arr = []
      if (this.advisor) {
        const year =
          this.advisor.anbieter.gruendungsjahr.length > 0
            ? this.advisor.anbieter.gruendungsjahr
            : 'n.v.'
        arr.push({
          title: 'Gründungsjahr',
          description: year
        })
        arr.push({
          title: 'Anbietertyp',
          description: this.advisor.anbieter.anbietertyp
        })
      }
      return arr
    }
  }
}
</script>
