<template>
  <v-btn v-bind="$attrs" class="button mr-2" v-on="$listeners"
    ><span class="button__text"><slot /></span
  ></v-btn>
</template>

<script>
export default {}
</script>

<style type="text/css" scoped>
@media (max-width: 600px) {
  .button {
    height: 44px !important;
  }
}

.button {
  display: inline-block;
  word-wrap: break-word;
}

.button__text {
  white-space: normal;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  color: #8eacca !important;
  background-color: transparent !important;
  border: 1.5px solid #8eacca;
}
</style>
