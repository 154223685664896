<template>
  <v-data-iterator
    :items="data"
    :sort-by="sortByKey"
    :sort-desc="sortDesc"
    loading
    disable-filtering
    disable-pagination
    hide-default-footer
    id="robo-advisor-table"
  >
    <template #header>
      <div class=" pt-8 pb-0 px-4 grey lighten-3">
        <v-row class="white-divider">
          <v-col cols="12" class="pa-0 ma-0">
            <p class="text-h1  text-center">
              Diese Anbieter für Robo-Advisor empfiehlt Ihnen die
              FMH-Finanzberatung
            </p>
          </v-col>
          <v-col cols="12" v-if="initalState" class="text-center ">
            Brechnungsgrundlage
            <v-chip label class="ma-1">Einmalbetrag</v-chip
            ><v-chip label class="ma-1">5.000 EUR</v-chip>
            <v-chip label class="ma-1">Anlagetyp "ausgewogen"</v-chip>
            <v-btn
              text
              class="primary--text"
              @click="$vuetify.goTo('#robo-advisor')"
              >Ändern</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="px-2">
          <v-col cols="12" class="px-1">
            <!-- v-model="sortBy" -->
            <v-select
              class="mb-1 d-md-none"
              solo
              hide-details
              :items="mobileSortingKeys"
              label="Liste sortieren"
              dense
              @change="changeSmartphoneSorting"
              return-object
            >
              <template v-slot:item="{ item }">
                {{ item.text }}
                <v-icon> {{ upOrDownIcon(item.desc) }}</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </div>
    </template>
    <template #default="props" v-if="!loadingRoboAdvisor">
      <v-simple-table>
        <template #default>
          <thead
            v-if="$vuetify.breakpoint.mdAndUp"
            class="grey lighten-3 grey--text  "
          >
            <tr>
              <th class="text-start py-0 text-subtitle-2">
                Robo-Advisor
                <div class="py-0 table-heading__slot" />
              </th>
              <th
                class="py-0 text-center text-subtitle-2 cursor-pointer table__header hidden-md-and-down"
                @click="sortByActivity"
              >
                <table-sorting-icon
                  v-show="sortedByValue('activity')"
                  :sorting="sortActivityDesc"
                />
                Activity
                <div class="py-0 table-heading__slot" />
              </th>
              <th class="py-0 text-center text-subtitle-2 cursor-pointer">
                <span @click="sortByPerformance" class="table__header">
                  <table-sorting-icon
                    v-show="sortedByValue('performance')"
                    :sorting="sortPerformanceDesc"
                  />

                  Performance
                </span>
                <div class="py-0 table-heading__slot ">
                  <div
                    style="width:100px"
                    class="justify-center pa-0 ma-0 float-right"
                  >
                    <v-select
                      v-model="year"
                      class="py-0 px-0 ma-0 text-caption font-weight-medium grey--text "
                      solo
                      hide-details
                      background-color="transparent"
                      :items="yearChoices"
                      dense
                      flat
                    ></v-select>
                  </div>
                </div>
              </th>
              <th
                class="pa-0 text-center text-subtitle-2 cursor-pointer table__header"
                @click="sortByFee"
              >
                <table-sorting-icon
                  v-show="sortedByValue('fee')"
                  :sorting="sortFeeDesc"
                />

                Gebühr
                <div class="py-0 table-heading__slot"></div>
              </th>
              <th class="pb-0 text-start text-subtitle-2 cursor-pointer">
                <ul style="list-style:none" class="pb-0 pl-0  ma-0 text-start">
                  <li @click="sortByStrategies">
                    <table-sorting-icon
                      v-show="sortedByValue('strategies')"
                      :sorting="sortStrategiesDesc"
                    ></table-sorting-icon>

                    Anlagestrategien
                  </li>
                  <li @click="sortByRebalancing">
                    <table-sorting-icon
                      v-show="sortedByValue('rebalancing')"
                      :sorting="sortRebalancingDesc"
                    />
                    Rebalancing
                  </li>
                  <li @click="sortByPromotion">
                    <table-sorting-icon
                      v-show="sortedByValue('promotion')"
                      :sorting="sortPromotionDesc"
                    />
                    Aktionen/ Boni
                  </li>
                </ul>
              </th>
              <th
                class="py-0 text-start text-subtitle-2  table__header cursor-pointer"
                @click="sortByAssets"
              >
                <span>
                  <table-sorting-icon
                    v-show="sortedByValue('assets')"
                    :sorting="sortAssetsDesc"
                  />
                  Asset Location</span
                >
                <div class="py-0 table-heading__slot" />
              </th>
              <th class="py-0 text-center"></th>
            </tr>
          </thead>
          <tbody class="hidden-sm-and-down">
            <template v-for="(advisor, index) in props.items">
              <tr :key="`summary-${index}`" class="row--no-hover">
                <td class="pr-0 mr-0">
                  <v-img
                    v-if="advisor.anbieter.anbieterLogoUrl.length > 0"
                    :src="advisor.anbieter.anbieterLogoUrl"
                    max-height="77"
                    max-width="155"
                  ></v-img>
                  <p v-else>{{ advisor.anbieter.anbietername }}</p>
                </td>
                <td class="text-center my-0  hidden-md-and-down">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="28"
                        color="black"
                        @click="openToTab(index, 2)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ activityRanking(advisor.serviceRanking) }}
                      </v-icon>
                    </template>
                    <span>Activity steht für...</span>
                  </v-tooltip>
                </td>
                <td
                  class="cursor-pointer text-center"
                  @click="openToTab(index, 0)"
                  :class="'text-h1 ' + performanceClassColor(advisor, year)"
                >
                  {{ numberToPercent(advisor, year) }}
                </td>
                <td
                  class="cursor-pointer  text-h1 px-0  text-center"
                  @click="openToTab(index, 0)"
                >
                  {{ numberToEuro(advisor.servicegebuehr) }}
                </td>
                <td class="text-body-2 ">
                  <p
                    v-if="advisor.produktDetails.anzahlStrategien !== undefined"
                    class="mb-0 mt-2"
                  >
                    {{ advisor.produktDetails.anzahlStrategien }}
                    Anlagestrategien
                  </p>
                  <p v-if="advisor.produktDetails.rebalancing" class="mb-0 ">
                    {{ advisor.produktDetails.rebalancing }}
                  </p>
                  <p class="mt-1">
                    <v-chip
                      v-if="
                        advisor.produktDetails.sonderaktionBemerkung.length > 0
                      "
                      label
                      small
                      class="gray mr-3"
                      dark
                    >
                      <b>Neukunden-Aktion</b></v-chip
                    >
                  </p>
                </td>
                <td style="max-width:67px" class="px-0 mx-0 text-start">
                  <apexchart
                    class="cursor-pointer"
                    @click="openToTab(index, 1)"
                    type="donut"
                    :options="baseChartOptions"
                    :series="
                      assetAllocationChartSeries(
                        advisor.produktDetails.assetAllocationKlassen
                      )
                    "
                    width="80%"
                  ></apexchart>
                </td>
                <td>
                  <v-row dense style="width: 120px">
                    <v-col cols="12" class="pa-0">
                      <a
                        :href="advisor.anbieter.angebotUrl"
                        target="_blank"
                        rel="sponsored"
                      >
                        <v-btn
                          color="primary darken-1 mt-2 px-2"
                          class=" white--text"
                        >
                          Anbieter
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </a>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <v-btn
                        v-if="!indexOfDetailsShown.includes(index)"
                        class="primary--text text-right my-2"
                        text
                        @click="indexOfDetailsShown.push(index)"
                      >
                        Details
                        <v-icon>mdi-menu-down</v-icon>
                      </v-btn>
                      <div
                        style="width:100%; height: 36px"
                        class=" my-2"
                        v-else
                      ></div>
                    </v-col>
                  </v-row>
                </td>
                <!-- <td v-else>
                  <p class="pl-8 mb-0 mt-2">z.Zt. keine</p>
                  <p class="pl-8 mb-0">Neukunden-</p>
                  <p class="pl-8">Aufnahme</p>
                </td> -->
              </tr>
              <tr
                v-if="indexOfDetailsShown.includes(index)"
                :key="`details-${index}`"
                class="row--no-hover"
              >
                <td colspan="7" class="pa-0">
                  <div class="d-none d-md-flex">
                    <fmh-details
                      :ref="`detail${index}`"
                      :advisor="advisor"
                      :tab-open="detailTabOpen[index]"
                      :detail-index="index"
                      @close="hideDetails(index)"
                    ></fmh-details>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
      <template v-for="(advisor, index) in props.items">
        <v-container :key="`summary-${index}`" class="hidden-md-and-up">
          <v-row class="mt-1">
            <v-layout justify-center>
              <v-img
                v-if="advisor.anbieter.anbieterLogoUrl.length > 0"
                :src="advisor.anbieter.anbieterLogoUrl"
                max-height="77"
                max-width="194"
              ></v-img>
              <p v-else>{{ advisor.anbieter.anbietername }}</p>
            </v-layout>
          </v-row>
          <v-row class="ma-0">
            <v-col class="pa-0">Activity</v-col>
            <v-col class="pa-0">
              <v-layout justify-end>
                <v-icon
                  class=" text-right mb-3"
                  size="28"
                  color="black"
                  @click="openToTab(index, 2)"
                >
                  {{ activityRanking(advisor.serviceRanking) }}
                </v-icon>
              </v-layout>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col class="pa-0">Performance</v-col>
            <v-col class="pa-0">
              <p
                @click="openToTab(index, 0)"
                :class="
                  'text-h1 text-right mb-1 ' +
                    performanceClassColor(advisor, year)
                "
              >
                {{ numberToPercent(advisor, year) }}
              </p>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col class="pa-0">Gebühr</v-col>
            <v-col class="pa-0">
              <p class="text-h1 mb-0 text-right" @click="openToTab(index, 0)">
                {{ numberToEuro(advisor.servicegebuehr) }}
              </p>
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col class="pa-0">AnlageStrategie</v-col>
            <v-col class="pa-0">
              <p
                v-if="advisor.produktDetails.anzahlStrategien !== undefined"
                class="mb-0 mt-2 text-right "
              >
                {{ advisor.produktDetails.anzahlStrategien }}
                strategien
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>Rebalancing</v-col>
            <v-col>
              <p
                v-if="advisor.produktDetails.rebalancing !== undefined"
                class="mb-0 text-right"
              >
                {{ advisor.produktDetails.rebalancing }}
              </p>
            </v-col>
          </v-row>

          <v-row v-if="advisor.produktDetails.sonderaktionBemerkung.length > 0">
            <v-col>Prämien</v-col>
            <v-col>
              <v-layout justify-end>
                <v-chip label small class="gray" dark>
                  <b>Neukunden-Aktion</b></v-chip
                >
              </v-layout>
            </v-col>
          </v-row>

          <v-row class="ma-0">
            <v-col class="chart-col pa-0">Asset Allocation</v-col>
            <v-col class="pa-0">
              <v-layout justify-end>
                <apexchart
                  @click="openToTab(index, 1)"
                  style="max-width:130px"
                  type="donut"
                  :options="baseChartOptions"
                  :series="
                    assetAllocationChartSeries(
                      advisor.produktDetails.assetAllocationKlassen
                    )
                  "
                ></apexchart>
              </v-layout>
            </v-col>
          </v-row>
          <template v-if="true">
            <v-row>
              <v-col>
                <a
                  :href="advisor.anbieter.angebotUrl"
                  target="_blank"
                  rel="sponsored"
                >
                  <v-btn
                    color="primary darken-1 mt-2"
                    class="d-block white--text"
                    block
                  >
                    Anbieter
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </a>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="!indexOfDetailsShown.includes(index)" class="pt-0">
                <v-btn
                  text
                  block
                  class="primary--text"
                  @click="indexOfDetailsShown.push(index)"
                >
                  Details
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
          </template>
          <v-row v-else>
            <p class="pl-8 mb-0 mt-2">z.Zt. keine</p>
            <p class="pl-8 mb-0">Neukunden-</p>
            <p class="pl-8">Aufnahme</p>
          </v-row>
        </v-container>
        <v-expand-transition :key="`details-${index}`">
          <span
            v-if="indexOfDetailsShown.includes(index)"
            :key="`details-${index}`"
            class="hidden-md-and-up"
          >
            <v-btn block color="primary" tile @click="hideDetails(index)">
              Schliessen
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="d-flex d-md-none">
              <fmh-details-smartphone
                :advisor="advisor"
              ></fmh-details-smartphone>
            </div>
          </span>
        </v-expand-transition>
      </template>
    </template>
    <template #loading>
      <div class="text-center" v-if="loadingRoboAdvisor">
        <v-progress-circular
          indeterminate
          :size="80"
          color="primary"
        ></v-progress-circular>
        Berechnung....
      </div>
      <div class="text-center py-10" v-else-if="!loadingRoboAdvisor">
        Kein Robo-Advisor passt zu Ihren Angaben. Bitte ändern Sie Ihre
        Einstellungen oben, um eine Auswahl von Anbietern angezeigt zu bekommen.
      </div>
    </template>
    <template #footer>
      <fmh-details-footer />
    </template>
  </v-data-iterator>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      investmentAmount: state => state.roboAdvisor.investmentAmount,
      savingsPlan: state => state.roboAdvisor.savingsPlan,
      withdrawalPlan: state => state.roboAdvisor.withdrawalPlan,
      assetTypes: state => state.roboAdvisor.assetTypes,
      initalState: state => state.roboAdvisor.initalState
    }),
    loadingRoboAdvisor: {
      get() {
        return this.$store.state.roboAdvisor.loading
      }
    },
    sortByKey() {
      if (this.sortBy === ('fee' || 'feeDesc')) {
        return 'servicegebuehr'
      } else if (this.sortBy === 'robo-advisor') {
        return 'anbieter.anbietername'
      } else if (this.sortBy === 'performance') {
        return 'produktDetails.currentPerformance'
      } else if (this.sortBy === 'activity') {
        return 'serviceRanking'
      } else if (this.sortBy === 'strategies') {
        return 'produktDetails.anzahlStrategien'
      } else if (this.sortBy === 'promotion') {
        return 'produktDetails.sonderaktionBemerkung'
      } else if (this.sortBy === 'rebalancing') {
        return 'produktDetails.rebalancing'
      } else if (this.sortBy === 'assets') {
        return 'produktDetails.assetAllocationKlassen'
      } else {
        return ''
      }
    },
    currentYear() {
      return new Date().getFullYear()
    },
    yearChoices() {
      const years = [{ value: this.currentYear.toString(), text: 'Aktuell' }]
      for (let i = 1; i <= 5; i++) {
        const year = (this.currentYear - i).toString()
        years.push({ value: year, text: year })
      }
      return years
    }
  },
  data() {
    return {
      itemsPerPageArray: [4, 8, 12],
      sortFeeDesc: false,
      sortPerformanceDesc: false,
      sortStrategiesDesc: false,
      sortActivityDesc: false,
      sortAssetsDesc: false,
      sortRebalancingDesc: false,
      sortPromotionDesc: false,
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: '',
      year: '2021',
      mobileSortingKeys: [
        {
          value: 'feeASC',
          name: 'fee',
          text: 'Gebühren aufsteigend',
          desc: false
        },
        {
          value: 'feeDESC',
          name: 'fee',
          text: 'Gebühren absteigend',
          desc: true
        },
        {
          value: 'performanceASC',
          name: 'performance',
          text: 'Performance aufsteigend',
          desc: false
        },
        {
          value: 'performanceDESC',
          name: 'performance',
          text: 'Performance absteigend',
          desc: true
        },
        {
          value: 'activityASC',
          name: 'activity',
          text: 'Activity aufsteigend',
          desc: false
        },
        {
          value: 'activityDESC',
          name: 'activity',
          text: 'Aktivity absteigend',
          desc: true
        },
        {
          value: 'strategiesASC',
          name: 'strategies',
          text: 'Strategien aufsteigend',
          desc: false
        },
        {
          value: 'strategiesDESC',
          name: 'strategies',
          text: 'Strategien absteigend',
          desc: true
        }
      ],

      baseChartOptions: {
        colors: [
          'var(--v-primary-darken1)',
          'var(--v-primary-base)',
          'var(--v-primary-darken3)',
          'var(--v-primary-darken4)',
          'var(--v-primary-lighten3)'
        ],
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        chart: {
          width: 50,
          height: 50,
          type: 'donut',
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          pie: {
            size: 50,
            expandOnClick: false
          }
        },
        options: {
          plotOptions: {
            donut: {
              size: 50
            }
          },

          hover: {
            animationDuration: 0 // duration of animations when hovering an item
          },
          responsiveAnimationDuration: 0, // animation duration after a resize

          legend: {
            position: 'bottom'
          }
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        }
      },
      indexOfDetailsShown: [],
      detailTabOpen: []
    }
  },
  methods: {
    sortedByValue(value) {
      return this.sortBy === value
    },
    upOrDownIcon(state) {
      if (state) return 'mdi-chevron-up'
      return 'mdi-chevron-down'
    },
    changeSmartphoneSorting(data) {
      this.sortBy = data.name
      this.resetSorting()
      const type = `sort${this.upperFirst(data.name)}Desc`
      this[type] = data.desc
      this.sortDesc = data.desc
      this.indexOfDetailsShown = []
    },
    openToTab(index, tabNum = 0) {
      this.hideDetails(index)
      this.detailTabOpen[index] = tabNum
      this.indexOfDetailsShown.push(index)
    },

    findYearPerformance(years) {
      const yearFound = years.filter(item => {
        return item.jahr.toString() === this.year
      })
      if (yearFound.length) return yearFound[0].rendite
      return null
    },
    resetSorting() {
      this.sortPerformanceDesc = false
      this.sortStrategiesDesc = false
      this.sortActivityDesc = false
      this.sortFeeDesc = false
      this.sortAssetsDesc = false
      this.sortRebalancingDesc = false
    },
    changeSorting(type, name) {
      const tempDesc = !this[type]
      this.resetSorting()
      this.sortDesc = tempDesc
      this[type] = tempDesc
      this.sortBy = name
      this.indexOfDetailsShown = []
    },
    sortByFee() {
      this.changeSorting('sortFeeDesc', 'fee')
    },
    sortByPerformance() {
      this.changeSorting('sortPerformanceDesc', 'performance')
    },
    sortByActivity() {
      this.changeSorting('sortActivityDesc', 'activity')
    },
    sortByStrategies() {
      this.changeSorting('sortStrategiesDesc', 'strategies')
    },
    sortByRebalancing() {
      this.changeSorting('sortRebalancingDesc', 'rebalancing')
    },
    sortByPromotion() {
      this.changeSorting('sortPromotionDesc', 'promotion')
    },
    sortByAssets() {
      this.changeSorting('sortAssetsDesc', 'assets')
    },

    activityRanking(activity) {
      if (activity >= 29 && activity <= 42) return 'mdi-activity-l'
      if (activity >= 15 && activity <= 28) return 'mdi-activity-m'
      if (activity <= 14) return 'mdi-activity-s'
    },
    upperFirst(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
    },
    valueToActivityIcon(value) {
      if (value > 0) return 'mdi-bell-ring-outline'
      else return 'mdi-bell-outline'
    },
    performanceClassColor(advisor, year) {
      let performance = 0
      if (year === this.currentYear.toString())
        performance = advisor.performance
      else
        performance = this.findYearPerformance(
          advisor.produktDetails.performanceRenditen,
          year
        )
      advisor.produktDetails.currentPerformance = performance
      if (performance === 0 || performance === null) {
        return 'grey--text'
      } else if (performance > 0) {
        return 'green--text'
      }
      return 'red--text'
    },
    numberToPercent(advisor, year) {
      let number = 0
      // if (year === this.currentYear.toString()) number = advisor.performance
      // else
      number = this.findYearPerformance(
        advisor.produktDetails.performanceRenditen,
        year
      )
      if (number === null) return '-'
      // debugger
      const numLength = number.toString().length
      // const lengthMin = number < 0 ? 6 : 5
      if (numLength === 1) {
        return number + ',000%'
      }
      return number.toFixed(3).replace(/\./g, ',') + '%'
    },
    calculateFees(fee, advisor) {
      const feeObject = advisor.produktDetails.gebuehren.find(
        element => element.abDepotwert >= fee
      )

      if (
        this.investmentAmount === 0 &&
        this.savingsPlan === 0 &&
        this.withdrawalPlan === 0
      )
        return 0

      if (!feeObject) return 0
      const feeProcent = feeObject.gebuehr / 100

      let resultingFee = 0
      if (this.assetTypes === 1) {
        resultingFee = (feeProcent * this.investmentAmount).toFixed(2)
      }
      if (this.assetTypes === 2) {
        resultingFee = (feeProcent * this.savingsPlan * 12).toFixed(2)
      }
      if (this.assetTypes === 3 || this.assetTypes === 4) {
        resultingFee += (feeProcent * this.withdrawalPlan).toFixed(2)
      }
      advisor.produktDetails.currentFees = resultingFee
      return resultingFee
    },
    numberToEuro(number) {
      const euroSign = ' €'
      return number + euroSign
    },
    assetAllocationChartOptions(assets) {
      const optionsToExtend = JSON.parse(JSON.stringify(this.baseChartOptions))
      const labels = []
      assets.forEach(asset => {
        labels.push(asset.bezeichnung)
      })
      optionsToExtend.labels = labels
      return optionsToExtend
    },
    assetAllocationChartSeries(assets) {
      const weights = []
      assets.forEach(asset => {
        weights.push(asset.gewichtung)
      })
      return weights
    },
    hideDetails(index) {
      this.indexOfDetailsShown = this.indexOfDetailsShown.filter(
        num => num !== index
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.table-heading__slot {
  height: 38px;
}
.apexcharts-tooltip,
.apexcharts-tooltip.active,
.apexcharts-xaxistooltip,
.apexcharts-xaxistooltip.active,
.apexcharts-marker {
  transition: none;
}

tbody {
  .row--no-hover:hover {
    background-color: transparent !important;
  }
}

.button--unclickable {
  pointer-events: none;
}

.chart-col {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
