<template>
  <v-app>
    <fmh-app-bar></fmh-app-bar>

    <slot></slot>
    <CookieControl locale="de">
      <template v-slot:bar>
        <h3>Ohne Cookies geht es nicht</h3>
        <p>
          Auch unsere Webseite verwendet Cookies: Wir verwenden technisch
          notwendige Cookies, damit unsere Webseite vollständig angezeigt wird
          und Sie sämtliche Funktionen nutzen können. Darüber hinaus wollen wir
          Analysecookies verwenden, um beispielsweise auszuwerten, welche
          Inhalte Sie gerne lesen und welche Funktionen Sie nutzen. Auch
          Marketingcookies möchten wir einsetzen, um Sie auf interessante
          Angebote aufmerksam zu machen. <br /><br />Sie können jederzeit - auch
          später noch - festlegen, welche außer den notwendigen Cookies sie
          zulassen wollen und welche nicht. Umfassende Informationen zu den
          Cookies finden Sie in unserer
          <a href="/datenschutz">Datenschutzerklärung</a>.
        </p>
      </template>
    </CookieControl>
    <fmh-drawer></fmh-drawer>
    <fmh-footer></fmh-footer>
  </v-app>
</template>

<script>
export default {}
</script>
<style lang="scss">
@media (min-width: 1904px) {
  .container {
    max-width: 1185px;
  }
}

.v-toolbar__content,
.v-toolbar__extension {
  padding-bottom: 0 !important;
}
.v-btn--active.btn__link--inactive::before {
  opacity: 0 !important;
}
</style>

<style scoped lang="scss">
@media only screen and (max-width: 1039px) {
  // .v-menu__content {
  //   left: 0 !important;
  //   max-width: 100% !important;
  //   width: 100vw;
  // }
}
@media only screen and (min-width: 1040px) {
  .v-menu__content {
    // padding-left: -40px;
  }
}
</style>
