<template>
  <router-link
    v-if="link.prefetch"
    :to="link.preferredPermalink"
    :title="link.title || link.preferredPermalink"
    :class="linkClass"
  >
    <slot></slot>
  </router-link>
  <a
    v-else
    :href="link.preferredPermalink"
    :title="link.title || link.preferredPermalink"
    :class="linkClass"
  >
    <slot></slot
  ></a>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    linkClass: {
      type: String,
      default: 'white--text pa-0 ma-0'
    },
    link: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="scss">
a:hover {
  color: #1194db !important;
  caret-color: #1194db !important;
}
</style>
