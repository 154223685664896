<template>
  <div class="background-arrow__image">
    <img
      class="hidden-md-and-up background-arrow__image--smartphone"
      src="/images/arrow-mobile.svg"
      alt="FMH-Arrow for mobile devices"
    />
    <img
      class="hidden-sm-and-down"
      width="100%"
      height="100%"
      src="/images/fhm-footer-arrow-primary.svg"
      alt="FMH-Arrow for desktop devices"
    />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.background-arrow__image {
  left: -50px;
  top: 0;
  position: absolute;
  display: block;
  background-position: top left;
  width: 90vw;
}

.background-arrow__image--smartphone {
  left: -50px;
  top: 50px;
  position: absolute;
  display: block;
  background-position: left;
  width: calc(100vw + 10px);
}
</style>
