<template>
  <span>
    <template v-if="checked">
      <v-icon color="green" right> mdi-check </v-icon>
    </template>
    <template v-else>
      <v-icon color="red" right> mdi-close </v-icon>
    </template>
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'CheckedText',

  props: {
    text: { type: String, default: '' },
    checked: Boolean
  }
}
</script>
