<template>
  <div
    class="faq__container pb-12 pb-md-0"
    title="FAQ zum Robo-Advisor"
    alt="FAQ zum Robo-Advisor: Illustration, Hände strecken nach oben zu Fragezeichen und den Buchstaben FAQ."
  >
    <v-row class="mt-2 pb-sm-6">
      <v-col cols="12" sm="6" class="pr-sm-16">
        <h4 class="secondary--text text--lighten-1 text-h1">
          {{ data.title }}
        </h4>
        <h3 class="white--text text-h2">
          {{ data.subtitle }}
        </h3>
        <h4 class="white--text text-subtitle-1 mt-5 mb-2">
          {{ data.headline }}
        </h4>
        <p class="text-body-1 white--text" v-html="data.text" />
      </v-col>
      <v-col cols="12" sm="6">
        <v-expansion-panels v-model="panel" accordion>
          <v-expansion-panel v-for="(item, i) in data.questions" :key="i">
            <v-expansion-panel-header class="primary--text  ">
              {{ item.question }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ item.answer }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      panel: 0
    }
  },
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    }
  }
}
</script>

<style scoped>
.faq__container {
  background-image: url('/images/faq.png');
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 340px 340px;
}
</style>
