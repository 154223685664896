<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="resultData"
      :single-expand="false"
      :expanded.sync="expanded"
      item-key="anbieter.anbietername"
      show-expand
      hide-default-footer
      no-data-text="Keine Daten vorhanden"
      @click:row="rowClicked"
    >
      <template v-slot:item.anbieter.anbietername="{ item }">
        <a class="anbieterlink" title="Weitere Informationen zum Angebot">
          <template v-if="item.anbieter.anbieterLogoUrl != ''">
            <img
              :alt="item.anbieter.anbietername"
              :src="item.anbieter.anbieterLogoUrl"
            />
          </template>
          <template v-else>
            {{ item.anbieter.anbietername }}
          </template>
        </a>
      </template>

      <template v-slot:item.serviceRanking="{ item }">
        {{ serviceRanking(item.serviceRanking) }}
      </template>

      <template v-slot:item.performance="{ item }">
        {{ prozent(item.performance, 3) }}
      </template>

      <template v-slot:item.servicegebuehr="{ item }">
        {{ formatEuro(item.servicegebuehr, 2) }}
      </template>

      <template v-slot:item.anlagestrategienRebalancing="{ item }">
        {{ anlagestrategie(item.produktDetails.anzahlStrategien) }}
        <br />
        {{ item.produktDetails.rebalancing }}
        <br />
        <template v-if="item.produktDetails.sonderaktionBemerkung != ''">
          Neukunden-Aktion
        </template>
      </template>

      <template v-slot:item.assetAllocation="{ item }">
        <template v-if="item.produktDetails.assetAllocationKlassen.length > 0">
          <v-icon color="primary">
            mdi-star
          </v-icon>
        </template>
      </template>

      <template v-slot:item.angebotslink="{ item }">
        <template v-if="item.anbieter.angebotUrl != ''">
          <v-btn
            link
            small
            :href="item.anbieter.angebotUrl"
            target="_blank"
            @click="angebotslinkClick(item)"
          >
            Anbieter
          </v-btn>
        </template>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="tableHeaders.length">
          <fmh-anbieter-details :result-item="item" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import FmhStats from '@/api/robo-advisor'
// import AnbieterDetails from './AnbieterDetails'

import { writeStats } from '../../../api/robo-advisor'
import RoboAdvisor, { settings } from '../../../mixins/RoboAdvisor'

export default {
  mixins: [RoboAdvisor],
  name: 'ResultTable',

  // components: {
  //   AnbieterDetails,
  // },

  data() {
    return {
      expanded: [],
      tableHeaders: [
        {
          text: 'Anbieter',
          value: 'anbieter.anbietername',
          align: 'start',
          sortable: true
        },
        {
          text: 'Activity',
          value: 'serviceRanking',
          align: 'center',
          sortable: true
        },
        {
          text: 'Performance',
          value: 'performance',
          align: 'center',
          sortable: true
        },
        {
          text: 'Gebühr',
          value: 'servicegebuehr',
          align: 'center',
          sortable: true
        },
        //{ text: 'Anlagestrategie / Rebalancing Prämien', value: 'anzahlStrategien', align: 'start', sortable: true },
        {
          text: 'Anlagestrategien Rebalancing Prämien',
          value: 'anlagestrategienRebalancing',
          align: 'start',
          sortable: true
        },
        {
          text: 'Asset Allocation',
          value: 'assetAllocation',
          align: 'center',
          sortable: false
        },
        {
          text: 'Zum Anbieter',
          value: 'angebotslink',
          align: 'center',
          sortable: false
        },
        //{ text: 'Investmentstil', value: 'investmentstil' },
        //{ text: 'Rebalancing', value: 'rebalancing' },
        { text: '', value: 'data-table-expand' }
      ]
    }
  },

  computed: {
    resultData() {
      return this.$store.state.result.resultData.data
    },
    resultDataState() {
      return this.$store.state.result.ts
    }
  },

  watch: {
    resultDataState() {
      this.updateResultTable()
    }
  },

  mounted() {
    this.updateResultTable()
  },

  methods: {
    formatEuro(value, digits) {
      return (
        value.toLocaleString('de-DE', {
          currency: 'EUR',
          minimumFractionDigits: digits
        }) +
        ' ' +
        settings.euroZeichen
      )
    },

    prozent(value, digits) {
      return (
        value.toLocaleString('de-DE', {
          minimumFractionDigits: digits
        }) +
        ' ' +
        settings.prozentZeichen
      )
    },

    stringArray(values) {
      let ret = ''

      for (let i = 0; i < values.length; i++) {
        if (i > 0) {
          ret = ret + ', '
        }
        ret = ret + values[i]
      }

      return ret
    },
    serviceRanking(value) {
      if (value >= 29) {
        return 'A'
      } else if (value >= 15) {
        return 'B'
      } else {
        return 'C'
      }
    },
    anlagestrategie(value) {
      if (value == 1) {
        return value + ' Strategie'
      } else {
        return value + ' Strategien'
      }
    },

    updateResultTable() {
      this.expanded = []
    },
    rowClicked(value) {
      const i = this.expanded.indexOf(value)
      if (i === -1) {
        this.expanded.push(value)

        // console.log(value);

        writeStats({
          aktionId: 6,
          detailsId: 2,
          anbietername: value.anbieter.anbietername
        })
      } else {
        this.expanded.splice(i, 1)
      }
    },

    angebotslinkClick(value) {
      console.log(value)

      FmhStats.writeStats({
        aktionId: 3,
        detailsId: 0,
        anbietername: value.anbieter.anbietername
      })
    }
  }
}
</script>

<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
