<template>
  <div>
    <fmh-detail-triple-cols class="pa-3 ">
      <template #header><h3 class="text-h3">Anlagestrategie</h3></template>
      <template #first-element>
        <fmh-detail-list :list="strategyList"></fmh-detail-list>
      </template>
      <template #second-element>
        <span class="text-subtitle2">Portfolio</span>
        <span class="float-right float-md-none ml-8">
          <b class="text-body-2">
            {{ advisor.produktDetails.anlagestrategiename }}</b
          >
        </span>
      </template>
    </fmh-detail-triple-cols>

    <fmh-detail-triple-cols class="pa-3 white-divider">
      <template #header><h3 class="text-h3">Asset Allocation</h3></template>
      <template #first-element>
        <div id="chart">
          <v-lazy>
            <apexchart
              type="donut"
              :options="extendedChartOptions"
              :series="series"
            ></apexchart>
          </v-lazy>
        </div>
      </template>
      <div class="pa-1 white-divider"></div>
      <template #second-element>
        <fmh-detail-list class="pl-0 mt-7" :list="strategies"></fmh-detail-list>
      </template>
    </fmh-detail-triple-cols>

    <fmh-detail-double-cols class="pa-3 py-8">
      <template #header><h3 class="text-h3">Anlageklassen</h3></template>
      <template #element>
        <icon-chip
          v-for="asset in advisor.produktDetails.anlageklassen"
          :key="asset.value"
          class="mr-2 mb-2"
          :icon="productIcon(asset.value)"
          >{{ asset.name }}</icon-chip
        >
      </template>
    </fmh-detail-double-cols>
  </div>
</template>

<script>
import FmhDetailList from '../../Common/DetailList.vue'
import IconChip from './IconChip.vue'
import { format, parseISO } from 'date-fns'
export default {
  components: { IconChip, FmhDetailList },
  props: {
    advisor: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chartOptions: {
        colors: [
          'var(--v-primary-darken1)',
          'var(--v-primary-base)',
          'var(--v-primary-darken3)',
          'var(--v-primary-darken4)',
          'var(--v-primary-lighten3)'
        ],
        animations: {
          enabled: false
        },
        labels: ['Anleihen', 'Aktien', 'Liquidität']
      },
      chart: {
        type: 'donut',
        animations: {
          enabled: false
        }
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 20
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  },
  computed: {
    strategyList() {
      return [
        {
          title: 'Selbsteinschätzung',
          description: this.investmentStrategy
        },
        {
          title: 'Auflagedatum',
          description: this.formatDate(
            this.advisor.produktDetails.anlagestrategieAuflagedatum
          )
        }
      ]
    },
    investmentStrategy() {
      const investmentStrategy = this.$store.state.roboAdvisor
        .investmentStrategy
      if (investmentStrategy === 1) return 'Konservativ'
      if (investmentStrategy === 2) return 'Ausgewogen'
      if (investmentStrategy === 3) return 'Risikoreich'
      return 'Nicht gesetzt'
    },
    series() {
      const weights = []
      if (this.advisor) {
        this.advisor.produktDetails.assetAllocationKlassen.forEach(asset => {
          weights.push(asset.gewichtung)
        })
      }
      return weights
    },
    extendedChartOptions() {
      const optionsToExtend = JSON.parse(JSON.stringify(this.chartOptions))
      if (this.advisor) {
        const labels = []
        this.advisor.produktDetails.assetAllocationKlassen.forEach(asset => {
          labels.push(asset.bezeichnung)
        })
        optionsToExtend.labels = labels
      }
      return optionsToExtend
    },
    strategies() {
      const arr = []
      if (this.advisor) {
        arr.push({
          title: 'Strategien ohne ESG',
          description: this.advisor.produktDetails.anzahlStrategienOhneEsg
        })
        arr.push({
          title: 'Strategien mit ESG',
          description: this.advisor.produktDetails.anzahlStrategienMitEsg
        })
      }
      return arr
    }
  },
  methods: {
    formatDate(date) {
      return format(parseISO(new Date(date).toISOString()), 'dd.MM.yyyy') // format(parseISO(date), 'dd.MM.yyyy')
    },
    productIcon(product) {
      return product ? 'check' : 'close'
    }
  }
}
</script>
