<template>
  <div class="divider text-h3 grey-headline">
    {{ data }}
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scope>
.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

.divider::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid #e0e0e0;
  margin-left: 1em;
}
</style>
