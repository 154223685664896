<template>
  <fmh-card-button v-bind="$attrs" v-on="$listeners">
    <template v-if="checked">
      <v-icon color="orange " size="20" left>mdi-check-circle</v-icon>
    </template>
    <slot />
  </fmh-card-button>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false
    }
  }
}
</script>
