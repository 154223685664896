<template>
  <v-row>
    <client-only>
      <v-col
        v-for="(parentLink, parentIndex) in links"
        :key="parentIndex"
        cols="12"
      >
        <ul class="navigation__list">
          <li>
            <FmhLink
              :link="parentLink"
              class="text-subtitle-1 navigation__link--white"
            >
              <v-icon class="primary--text text--darken-1"
                >mdi-chevron-right</v-icon
              >
              {{ parentLink.title }}
            </FmhLink>
          </li>
          <li>
            <NavigationLinks :parent-link="parentLink"></NavigationLinks>
          </li>
        </ul>
      </v-col>
    </client-only>
  </v-row>
</template>

<script>
export default {
  props: {
    links: {
      type: [Object, Array],
      default: () => {},
    },
  },
}
</script>
<style scoped>
a {
  color: #fff !important;
}
</style>
