<template>
  <div>
    <!-- TODO: Refactore Component -->
    <client-only>
      <v-app-bar
        color="white"
        elevation="2"
        :height="$vuetify.breakpoint.xsOnly ? '135' : '60'"
        class="sticky-bar"
      >
        <v-container class="my-0 py-0">
          <v-row justify="space-between" class="sticky_bar--left">
            <slot name="smartphoneTitle"></slot>
            <slot></slot>
          </v-row>
        </v-container>
      </v-app-bar>

      <v-app-bar
        v-if="fixedStickyBar"
        :fixed="true"
        color="white"
        elevation="2"
        style="transition: top 0.3s"
        :class="{ 'top-position': scrollTop }"
        :height="$vuetify.breakpoint.xsOnly ? '135' : '60'"
      >
        <v-container class="my-0 py-0">
          <v-row justify="space-between" class="sticky_bar--left">
            <slot></slot>
          </v-row>
        </v-container>
      </v-app-bar>
    </client-only>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stickyBarMainPosition: 0,
      fixedStickyBar: false,
      fixedStyle: true,
      prevScrollpos: 0,
      scrollTop: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.fixedStickyBar = window.scrollY >= 154
      this.fixedStyle = window.scrollY <= 300

      const currentScrollPos = window.pageYOffset
      if (this.prevScrollpos > currentScrollPos) {
        this.scrollTop = false
        // document.getElementById('navbar').style.top = '0'
      } else {
        this.scrollTop = true
        // document.getElementById('navbar').style.top = '-50px'
      }
      this.prevScrollpos = currentScrollPos
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 1264px) {
  .sticky_bar--left {
    margin-left: 80px;
  }
}

.sticky-bar {
  height: 60px;
}

.top-position {
  top: -135px !important;
}
</style>
