import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import vuetifyConfig from './vuetify'

const options = {
  vuetify: vuetifyConfig,
  customProperties: true
}

Vue.use(Vuetify)

// export default ctx => {
//   const vuetify = new Vuetify(options.vuetify)
//   ctx.app.vuetify = vuetify
//   ctx.$vuetify = vuetify.framework
// }

export default new Vuetify(options.vuetify)
