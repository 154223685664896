<template>
  <div>
    <outlined-link-card :data="data"></outlined-link-card>
    <large-text-image-preview :data="data"> </large-text-image-preview>
  </div>
</template>

<script>
import LargeTextImagePreview from './LargeTextImagePreview.vue'
import OutlinedLinkCard from './OutlinedLinkCard.vue'
export default {
  components: { OutlinedLinkCard, LargeTextImagePreview },
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    }
  }
}
</script>
