<template>
  <ul class="detail--list pl-md-3 pl-0   pt-0 mt-0">
    <li v-for="(item, key) in list" :key="key">
      <v-row>
        <v-col cols="6" class="pl-md-0 pl-3 pt-0 mt-0">
          <span class="text-subtitle2 gray-text">{{ item.title }}</span></v-col
        >
        <v-col cols="6" class="pl-md-0 pl-3 pt-0 mt-0">
          <span class="float-right float-md-none text-right text-md-left">
            <b class="text-body-2" v-html="item.description"></b> </span
        ></v-col>
      </v-row>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.detail--list {
  list-style-type: none;
}
</style>
