<template>
  <v-card flat color="white" height="40" class="pa-0">
    <v-text-field
      v-model="query"
      dense
      persistent-hint
      solo
      width="100%"
      flat
      :background-color="color"
      class="rounded-1 pa-0 ma-0"
      label="Fragen Sie uns..."
      :append-icon="!focused || !query ? 'mdi-magnify' : ''"
      filled
      single-line
      :clear-icon="focused ? 'mdi-close-circle' : ''"
      clearable
      @blur="offFocus"
      @focus="onFocus"
      @keyup.enter="search"
      @click:append="search"
    ></v-text-field>
  </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#f0f0f0'
    }
  },
  data() {
    return {
      query: '',
      focused: false
    }
  },
  methods: {
    onFocus() {
      this.focused = true
    },
    offFocus() {
      this.focused = false
    },
    search() {
      window.location.href =
        'https://www.fmh.de/search?search-switch=on&search=' + this.query
    }
  }
}
</script>
