<template>
  <ul class="navigation__list my-0">
    <li v-if="withParent">
      <FmhLink :link="parentLink"
        ><a
          class="text-h3 navigation__link--white"
          @click="navigationDrawer = false"
        >
          <v-icon class="primary--text text--darken-1"
            >mdi-chevron-right</v-icon
          >
          {{ parentLink.title }}
        </a>
      </FmhLink>
    </li>
    <li
      v-for="(childLink, childIndex) in parentLink.children"
      :key="childIndex"
      class="pt-3 pt-md-0"
    >
      <FmhLink :link="childLink"
        ><a
          class="text-h3 navigation__link--white"
          @click="navigationDrawer = false"
        >
          <v-icon class="primary--text text--darken-1"
            >mdi-chevron-right</v-icon
          >
          {{ childLink.title }}
        </a>
      </FmhLink>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    parentLink: {
      type: [Array, Object],
      default: () => {},
    },
    withParent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    navigationDrawer: {
      get() {
        return this.$store.state.navigation.drawer
      },
      set(data) {
        return this.$store.commit('navigation/updateDrawer', data)
      },
    },
  },
}
</script>
