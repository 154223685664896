<template>
  <v-fade-transition>
    <v-card flat rounded="0">
      <v-alert :type="type" text class="mb-0">
        <div v-if="list">
          <ul v-for="(item, index) in message" :key="index">
            <li v-if="item.length > 0">
              <span>{{ item[0] }}</span>
            </li>
          </ul>
        </div>
        <div v-else>
          <p v-if="message" class="mb-0">{{ message }}</p>
          <slot v-else></slot>
        </div>
      </v-alert>
    </v-card>
  </v-fade-transition>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'error'
    },
    list: {
      type: Boolean,
      default: true
    },
    message: {
      type: [String, Object],
      default: () => {}
    }
  }
}
</script>
