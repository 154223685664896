<template>
  <div class="details__background">
    <v-row class="mx-0">
      <v-col cols="9" class="pa-0">
        <v-tabs
          v-model="detailTab"
          centered
          :show-arrows="false"
          background-color="transparent"
          class="text-center text-gray"
        >
          <v-tabs-slider color="transparent"></v-tabs-slider>
          <v-tab
            v-for="item in components"
            :key="item.name"
            active-class="white"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="3">
        <v-btn
          text
          class="blue--text text--darken-4 text-right float-right close-button mr-0"
          @click="$emit('close')"
        >
          Schliessen
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-tabs-items v-model="detailTab">
      <v-tab-item v-for="(component, key) in components" :key="key">
        <v-card color="gray lighten-1" flat>
          <component
            :is="'Fmh' + component.name"
            :key="key"
            :advisor="advisor"
          ></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import strategyCard from './StrategyCard'
export default {
  name: 'Details',
  components: { strategyCard },
  props: {
    advisor: {
      type: Object,
      default: () => {}
    },
    tabOpen: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      detailTab: null
    }
  },
  computed: {
    components() {
      const baseComponents = [
        { name: 'ProductCard', title: 'Produktdetails' },
        { name: 'StrategyCard', title: 'Strategie' },
        { name: 'ActivityCard', title: 'Activity' }
      ]
      if (this.advisor.anbieter.auszeichnungen.length > 0) {
        baseComponents.push({
          name: 'AwardCard',
          title: 'Auszeichnungen'
        })
      }

      baseComponents.push({
        name: 'AboutCard',
        title: 'Mehr über ' + this.advisor.anbieter.anbietername
      })
      return baseComponents
    },
    items() {
      const itemsArray = [
        'Produktdetails',
        'Strategie',
        'Activity',
        'Auszeichnungen'
      ]
      itemsArray.push('Mehr über ' + this.advisor.produktDetails.anbietername)
      return itemsArray
    }
  },
  watch: {
    tabOpen: {
      handler() {
        this.detailTab = this.tabOpen
      },
      immediate: true
    }
  }
}
</script>
<style scoped>
.details__background {
  background-color: var(--v-gray-lighten1);
  width: 100%;
}
.close-button {
  margin-right: 6% !important;
  margin-left: 20% !important;
}
</style>
