<template>
  <div>
    <fmh-detail-double-cols class="pa-3 white-divider">
      <template #header
        ><h3 class="text-h3">Kontakt & Interaktion</h3></template
      >
      <template #element>
        <fmh-icon-chip
          v-for="(item, key) in contactInteraction"
          :key="key"
          class="mr-2 mb-2 mb-lg-0"
          :icon="item.icon"
          >{{ item.name }}</fmh-icon-chip
        >
      </template>
    </fmh-detail-double-cols>
    <fmh-detail-double-cols class="pa-3 white-divider">
      <template #header><h3 class="text-h3">Kundenbetreuung</h3></template>
      <template #element>
        <ul>
          <li v-for="(item, key) in support" :key="key">
            {{ item }}
          </li>
        </ul>
      </template>
    </fmh-detail-double-cols>
    <fmh-detail-double-cols class="pa-3 pb-5">
      <template #header><h3 class="text-h3">Information</h3></template>
      <template #element>
        <ul>
          <li v-for="(item, key) in information" :key="key">
            {{ item }}
          </li>
        </ul>
      </template>
    </fmh-detail-double-cols>
  </div>
</template>

<script>
export default {
  props: {
    advisor: {
      type: Object,
      default: null
    }
  },
  computed: {
    contactInteraction() {
      const interactions = [
        'Telefon',
        'Email',
        'Chat',
        'Social Media',
        'Mobile App',
        'Events'
      ]
      const arr = []
      if (this.advisor) {
        interactions.forEach(element => {
          arr.push({
            name: element,
            icon: this.activityIcon(
              this.advisor.kundenservice[this.camelize(element)]
            )
          })
        })
      }
      return arr
    },
    support() {
      const arr = []
      if (this.advisor) {
        if (this.advisor.kundenservice.persoenlicherAnsprechpartner) {
          arr.push('Persönlicher Ansprechpartner')
        }
        if (this.advisor.kundenservice.persoenlicherFinanzberater) {
          arr.push('Persönlicher Finanzberater')
        }
      }
      if (arr.length === 0) {
        arr.push('Keine')
      }
      return arr
    },
    information() {
      const arr = []
      if (this.advisor) {
        arr.push(
          this.newsletterOptions[this.advisor.kundenservice.newsletterversand]
        )
        arr.push(this.reportingOptions[this.advisor.kundenservice.reporting])
        // if (this.advisor.kundenservice.newsletterversand) {
        //   arr.push('Wöchentlicher Newsletter')
        // }
        // if (this.advisor.kundenservice.reporting) {
        //   arr.push('Individuelles Reporting')
        // }
      }
      if (arr.length === 0) {
        arr.push('Keine')
      }
      return arr
    }
  },
  data() {
    return {
      newsletterOptions: [
        'Kein Newsletter',
        'Wöchentlicher Newsletter',
        'Zweiwöchentlicher Newsletter',
        'Monatlicher Newsletter',
        'Gelegentlicher Newsletter'
      ],
      reportingOptions: ['Monatliches Reporting', 'Vierteljährliches Reporting']
    }
  },
  methods: {
    activityIcon(activity) {
      return activity ? 'check' : 'close'
    },
    camelize(str) {
      return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase()
        })
        .replace(/\s+/g, '')
    }
  }
}
</script>
