<template>
  <v-row class="pb-8">
    <v-col cols="12" class="text-center">
      <h2 class="text-h2">
        <v-icon style="font-size: 96px" color="black">
          mdi-lightbulb-on-outline
        </v-icon>
        <p v-text="data.title" />
      </h2>
    </v-col>
    <v-col cols="12" sm="6">
      <h3 class="text-h2" v-text="data.subtitle" />
      <p class="text-body-2 pt-1" v-text="data.text" />
      <v-layout class="justify-center">
        <client-only>
          <FmhLink :link="data.button">
            <v-btn
              color="secondary darken-1"
              outlined
              v-text="data.button.title"
            />
          </FmhLink>
        </client-only>
      </v-layout>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card height="100%" width="100%" flat>
        <v-img
          width="100%"
          height="100%"
          :src="data.image"
          :alt="data.title"
        ></v-img>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
