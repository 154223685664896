<template>
  <v-card color="secondary darken-4" elevation="7">
    <v-card-text>
      <form @keyup.enter="validateRegistration">
        <div v-if="!registrated">
          <ValidationObserver
            ref="form"
            v-slot="{ errors: formErrors, failed: formFailure }"
          >
            <h3 class="text-h2 text-center white--text pb-5 pt-2">
              Jetzt registrieren!
            </h3>

            <AlertMessage
              v-if="formFailure"
              :list="true"
              :message="formErrors"
              type="error"
            ></AlertMessage>

            <ValidationProvider
              v-slot="{ failed }"
              mode="lazy"
              name="email"
              rules="required|email"
            >
              <label for="email" class="text-body-2 white--text">
                E-Mail-Adresse *
              </label>
              <v-text-field
                id="email"
                v-model="email"
                outlined
                background-color="white"
                dense
                :error="failed"
                name="email"
                :placeholder="emailPlaceholder"
                @focus="emailPlaceholder = ''"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ failed }"
              mode="lazy"
              name="password"
              rules="required|min:5"
            >
              <label for="password" class="text-body-2 white--text"
                >Passwort *</label
              >
              <v-text-field
                id="password"
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                outlined
                background-color="white"
                dense
                :error="failed"
                name="password"
                :placeholder="passwordPlaceholder"
                @focus="passwordPlaceholder = ''"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ failed }"
              mode="lazy"
              name="confirmation"
              rules="required|confirmation:@password"
            >
              <label for="confirmation" class="text-body-2 white--text"
                >Passwort Wiederholung *
              </label>
              <v-text-field
                id="confirmation"
                v-model="confirmation"
                :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmation ? 'text' : 'password'"
                outlined
                background-color="white"
                dense
                :error="failed"
                name="confirmation"
                :placeholder="confirmationPlaceholder"
                @focus="confirmationPlaceholder = ''"
                @click:append="showConfirmation = !showConfirmation"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ failed }"
              mode="lazy"
              name="privacy"
              rules="checked"
            >
              <v-row>
                <v-col cols="2" xs="1" sm="1">
                  <v-checkbox
                    id="privacy"
                    v-model="privacy"
                    dark
                    :error="failed"
                    name="primary"
                    class="pt-0 mt-0"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="10" xs="1" sm="11">
                  <span
                    slot="label"
                    style="cursor: pointer"
                    class="text-body-2 white--text-light"
                    @click="privacy = !privacy"
                  >
                    Bitte bestätigen Sie, dass Sie die

                    <label for="privacy">
                      <a href="/datenschutz" class="navigation__link--white"
                        >Datenschutzrichtlinien</a
                      >
                    </label>
                    der FMH-Finanzberatung gelesen haben und diese akzeptieren.
                  </span>
                </v-col>
              </v-row>
            </ValidationProvider>
            <v-row>
              <v-col cols="12" lg="6" offset="0" offset-lg="3">
                <v-btn
                  :loading="loading"
                  block
                  color="secondary darken-1"
                  @click="validateRegistration"
                  >Registrieren
                </v-btn></v-col
              >
            </v-row>
            <p class="white--text-light">* Pflichtfelder</p>
          </ValidationObserver>
        </div>
        <div v-else>
          <AlertMessage type="success" :list="false">
            Sie haben sich erfolgreich registriert. <br />
            Bitte schauen Sie in Ihr Postfach und bestätigen Sie unsere E-Mail.
          </AlertMessage>
          <!-- <v-alert type="success"
        ></v-alert
      > -->
        </div>
      </form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      registrated: false,
      privacy: false,
      email: '',
      password: '',
      confirmation: '',
      showPassword: false,
      showConfirmation: false,
      emailPlaceholder: 'E-Mail-Adresse',
      passwordPlaceholder: 'Mindestens 5 Zeichen',
      confirmationPlaceholder: 'Passwort Wiederholung',
      loading: false
    }
  },
  methods: {
    async validateRegistration() {
      const validate = await this.$refs.form.validate()
      if (!validate) {
        return
      }
      const user = {
        email: this.email,
        password: this.password,
        roles: ['editor']
      }
      this.loading = true
      const result = await this.$http().user.register(user)
      this.loading = false
      if (result) {
        this.email = ''
        this.password = ''
        this.privacy = false
        this.registrated = true
      }
    }
  }
}
</script>
