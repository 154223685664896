<template>
  <v-card
    flat
    rounded="0"
    color="secondary darken-4"
    class="small-newsletter__card"
  >
    <v-card-text class="white--text">
      <ValidationObserver ref="form">
        <form @submit.prevent="sendEmail">
          <h3 class="secondary--text text--lighten-1 pt-0 text-h2">
            Zinsen, Zahlen und Vergleiche
          </h3>
          <h2 class="text-subtitle-1 pt-0">
            Mit dem FMH Newsletter bestens informiert!
          </h2>
          <p class="pt-2 text-body-2">
            Abonnieren Sie unseren Newsletter mit transparenten
            Finanzmarktanalysen und unabhängiger Beratung
          </p>
          <EmailNewsletterTextField
            v-model="email"
            class=""
          ></EmailNewsletterTextField>

          <v-btn
            color="secondary darken-1"
            block
            class="mt-3"
            @click="sendEmail"
            :loading="loading"
          >
            Jetzt anmelden</v-btn
          >
        </form>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      registered: false,
      loading: false
    }
  },
  methods: {
    async sendEmail() {
      const validation = await this.$refs.form.validate()

      if (!validation) {
        return null
      }
      const data = {
        email: this.email,
        tags: ['Large Newsletter', 'landingpage']
      }

      this.loading = true
      const result = await this.$http().user.newsletter(data)
      this.loading = false

      if (result) {
        this.email = ''
        this.registrated = true
      }
    }
  }
}
</script>

<style scoped>
.small-newsletter__card {
  background-image: url('/images/Newsletter.svg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 280px 280px;
}
</style>
