<template>
  <div class="text-center mb-5">
    <v-row justify="center">
      <v-col cols="12" sm="6" class="pt-1">
        <div>
          <v-icon color="black" class="pt-1" style="font-size: 96px">
            mdi-{{ data.title_icon }}
          </v-icon>
          <h2 class="text-h1 pt-2">
            {{ data.title }}
          </h2>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(item, idx) in data.reasons"
        :key="idx"
        cols="12"
        sm="6"
        :offset-sm="item.justify === 'center' ? 3 : 0"
      >
        <div class="reason__card px-5 pt-3 pb-7">
          <v-icon color="black" class="pt-0" style="font-size: 64px">
            mdi-{{ item.title_icon }}
          </v-icon>
          <h2 class="text-h2 pt-0">
            {{ item.title }}
          </h2>
          <p class="my-1 text-body-2" align="left">
            {{ item.text }}
          </p>
          <FmhLink :link="item.button">
            <v-btn class="px-10 mt-2" color="secondary darken-2" outlined>
              {{ item.button.title }}
            </v-btn>
          </FmhLink>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row justify="center">
      <v-col cols="12" sm="6">
        <div class="reason__card px-5 py-5">
          <v-icon color="black" x-large>
            {{ data.reason.title_icon }}
          </v-icon>
          <h2 class="text-h2">
            {{ data.reason.title }}
          </h2>
          <p class="my-2" align="left">
            {{ data.reason.text }}
          </p>
          <FmhLink :link="data.reason.button">
            <v-btn class="px-10 mt-2" color="secondary darken-2" outlined>
              {{ data.reason.button.title }}
            </v-btn>
          </FmhLink>
        </div>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    }
  }
}
</script>

<style>
.reason__card {
  border-style: solid;
  border-color: white;
  border-width: 6px;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

/* .reason__card::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 16px 16px 0;
  border-style: solid;
  border-color: #fff #fff #658e15 #658e15;
  background: #658e15;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3),
    -1px 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  display: block;
  width: 0;
} */

.text-icon {
  font-size: 30px;
  font-weight: 600;
  vertical-align: middle;
}
</style>
