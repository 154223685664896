<template>
  <v-toolbar
    color="white"
    height="auto"
    elevation="1"
    z-index="30"
    style="z-index: 30"
    class="pb-0 navigation__toolbar elevation-10"
  >
    <v-container class="pa-0 navigation__container">
      <v-row>
        <v-col cols="8" md="5" class="py-0">
          <FmhLink :link="homeLink">
            <img
              class="navigation__image mt-2 mt-md-4 hidden-xs-only"
              src="/images/fmh-zinsen-zahlen-vergleiche.svg
              "
              width="auto"
              alt="FMH Logo"
            />
            <img
              class="navigation__image mt-2 mt-md-2 hidden-sm-and-up"
              src="/images/fmh_logo.svg"
              width="auto"
              alt="FMH Logo"
            />
          </FmhLink>
        </v-col>
        <v-col cols="4" class="hidden-md-and-up py-0">
          <v-layout class="justify-end">
            <v-btn
              icon
              class="mt-2"
              aria-label="fmh-smartphone-menu"
              @click="navigationDrawer = !navigationDrawer"
            >
              <v-icon color="primary">mdi-menu</v-icon>
            </v-btn>
          </v-layout>
        </v-col>
        <v-col cols="7" class="hidden-sm-and-down pt-0 pb-7">
          <v-row class="justify-end">
            <v-col cols="12" class="pb-2">
              <v-layout class="justify-end">
                <v-card
                  class="text-subtitle-2 primary--text"
                  flat
                  color="white"
                >
                  <span
                    v-for="(link, linkIndex) in staticHeaderLinks"
                    :key="linkIndex"
                  >
                    <FmhLink
                      :link="link"
                      link-class="navigation__link--primary"
                    >
                      {{ link.title }}
                    </FmhLink>
                    <span
                      v-if="linkIndex < staticHeaderLinks.length - 1"
                      class="pa-1"
                    >
                      |
                    </span>
                  </span>
                </v-card>
              </v-layout>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-layout class="justify-end">
                <Search style="width: 402px"></Search>
              </v-layout>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="hidden-sm-and-down py-1 mr-0 mb-0 pb-0"
          style="padding-top: 6px !important"
        >
          <v-layout class="justify-end">
            <v-card tile rounded="0" flat class="ma-0 pa-0">
              <v-row>
                <v-col cols="12" class="ml-0 mb-0 py-0" style="width: 1135px">
                  <v-card class="justify-space-between d-flex" flat>
                    <v-card
                      v-for="(parentLink, parentLinkIndex) in navigationLinks"
                      :key="parentLinkIndex"
                      flat
                    >
                      <v-menu
                        offset-y
                        elevation="2"
                        z-index="2"
                        rounded="0"
                        nudge-left="30px"
                        open-on-hover
                        :allow-overflow="true"
                      >
                        <!-- open-on-hover -->
                        <template v-slot:activator="{ on, attrs }">
                          <!-- :color="link.active ? 'secondary' : 'primary'" -->

                          <!-- :to="parentLink.preferredPermalink" -->

                          <v-btn
                            v-if="parentLink.prefetch"
                            color="primary"
                            class="py-5 px-1 mb-0 navigation--spacing"
                            tag="a"
                            dense
                            text
                            depressed
                            :to="parentLink.preferredPermalink"
                            active-class="secondary--text btn__link--inactive"
                            :small="$vuetify.breakpoint.mdAndDown"
                            v-bind="attrs"
                            :ripple="false"
                            exact-active-class="white"
                            v-on="on"
                          >
                            {{ parentLink.title }}
                          </v-btn>
                          <v-btn
                            v-else
                            color="primary"
                            class="py-5 px-1 mb-0 navigation--spacing"
                            text
                            tag="a"
                            :ripple="false"
                            depressed
                            :href="parentLink.preferredPermalink"
                            dense
                            :small="$vuetify.breakpoint.mdAndDown"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ parentLink.title }}
                          </v-btn>
                        </template>
                        <v-card
                          elevation="2"
                          height="auto"
                          rounded="0"
                          color="primary"
                          class="hidden-sm-and-down pb-5 mt-0 pt-0 pr-5 rounded-0"
                        >
                          <v-layout
                            :class="{
                              'justify-end':
                                $vuetify.breakpoint.mdAndDown &&
                                parentLink.title === 'Bankenbewertung',
                            }"
                          >
                            <v-icon
                              color="#fff"
                              large
                              elevation="0"
                              tag="div"
                              class="elevation-0 mt-0 mt-0 pt-0 mx-14 justify-end navigation__menu--active"
                              >mdi-menu-down
                            </v-icon>
                          </v-layout>
                          <NavigationLinks
                            :parent-link="parentLink"
                          ></NavigationLinks>
                        </v-card>
                      </v-menu>
                    </v-card>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<script>
export default {
  data() {
    return {
      homeLink: {
        title: 'Startseite',
        preferredPermalink: '/',
        prefetch: false,
      },
    }
  },
  computed: {
    navigationDrawer: {
      get() {
        return this.$store.state.navigation.drawer
      },
      set(data) {
        return this.$store.commit('navigation/updateDrawer', data)
      },
    },
    staticHeaderLinks() {
      return this.$store.state.navigation.staticHeaderLinks
    },
    navigationLinks() {
      return this.$store.state.navigation.links
    },
  },
}
</script>

<style lang="scss">
.navigation {
  &__container {
    height: auto;
    max-width: 1210px;
  }
  &__toolbar {
    min-height: 70px;
    max-height: 160px !important;
  }
  &__divider {
    background: #0066a1;
  }
  &__menu {
    left: 0 !important;
    max-width: 100% !important;
    &--active {
      height: 20px;
      top: -6px;
      z-index: 11;
      font-size: 56px !important;
    }
  }
  &__image {
    max-height: 85px;
  }

  &__list {
    list-style-type: none;
  }
  @media only screen and (max-width: 959px) {
    &__image {
      height: 45px !important;
      max-height: 80px !important;
      background-position: none !important;
    }
  }

  @media only screen and (min-width: 960px) {
    &__toolbar {
      height: 160px;
    }
  }

  @media only screen and (max-width: 1039px) {
    &--spacing {
      margin-left: 13px;
    }
  }
  @media only screen and (min-width: 1040px) and (max-width: 1200px) {
    &--spacing {
      margin-left: 15px;
    }
  }

  @media only screen and (min-width: 1201px) {
    &--spacing {
      //  margin-left: 30px;
      margin-right: 0;
    }
  }
}
</style>
