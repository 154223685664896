import colors from 'vuetify/es5/util/colors'
// // import fmhColors from './fmh-colors'
import fmhColors from './fmh-colors'

const data = {
  // optionsPath: './plugins/vuetify.js',
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  treeShake: true, // process.env.SYSTEM !== 'storybook',
  defaultAssets: false,
  // customVariables: ['../variables.scss'],

  theme: {
    options: { customProperties: true },
    dark: false,
    light: true,
    themes: {
      light: {
        primary: fmhColors.primary,
        accent: colors.grey.darken3, // Missing
        secondary: fmhColors.secondary,
        info: colors.teal.lighten1, // Missing
        warning: colors.amber.base, // Missing
        error: fmhColors.error,
        success: fmhColors.success,
        gray: fmhColors.gray,
        red: fmhColors.red
      }
    }
  }
}

// export default data

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import vuetifyConfig from './vuetify'

const options = {
  vuetify: data
}

Vue.use(Vuetify)

export default new Vuetify(options.vuetify)
