<template>
  <div>
    <fmh-detail-double-cols
      class="pa-3 "
      :class="{ awardsActive: 'white-divider' }"
    >
      <template #header><h3 class="text-h3">Beschreibung</h3></template>
      <template #element>
        Auszeichnungen der FMH-Finanzberatung sind ein klares Zeichen für
        nachweisliche Anbieterqualität. Sie basieren auf unabhängiger
        Datenauswertung und sind ausschließlich für einen kurzen Zeitraum
        gültig. Auf die folgenden Auszeichnungen des Anbieters ist Verlass – sie
        sind aktuell und FMH-geprüft.
      </template>
    </fmh-detail-double-cols>
    <fmh-detail-double-cols class="pa-3 pb-5" v-if="awardsActive">
      <template #header><h3 class="text-h3">Auszeichnungen</h3></template>
      <template #element>
        <v-row>
          <v-col v-for="(award, key) in awards" :key="key" cols="12" md="3">
            <v-img :src="award.url"></v-img
          ></v-col>
        </v-row>
      </template>
    </fmh-detail-double-cols>
  </div>
</template>

<script>
export default {
  props: {
    advisor: {
      type: Object,
      default: null
    }
  },
  computed: {
    awardsActive() {
      return this.awards.length > 0
    },
    awards() {
      if (this.advisor) {
        return this.advisor.anbieter.auszeichnungen
      }
      return []
    }
  }
}
</script>
<style scoped lang="scss">
.image {
  &--seal {
    max-width: 120px;
  }
}
</style>
