<template>
  <v-app>
    <!-- <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://www.fmh.de/images/fmh-zinsen-zahlen-vergleiche.svg"
          width="100%"
        />
      </div>

      <v-spacer></v-spacer>
    </v-app-bar> -->

    <v-main>
      <v-container class="px-0 px-md-2">
        <robo-calculator></robo-calculator>

        <fmh-details-table :data="results.data"></fmh-details-table>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import RoboCalculator from './components/Calculator/new-robo-advisor/RoboCalculator.vue'
// import HelloWorld from "./components/HelloWorld";
import { mapActions } from 'vuex'

export default {
  components: { RoboCalculator },
  name: 'App',

  async created() {
    await this.getResults()
  },
  computed: {
    results: {
      get() {
        return this.$store.state.roboAdvisor.results
      }
    }
  },
  methods: {
    ...mapActions({
      getResults: 'roboAdvisor/getResults'
    })
  }
}
</script>
<style>
@import './style.css';
</style>
