const state = () => ({
  rawLinks: {},
  links: {},
  leftFooterLinks: [],
  rightFooterLinks: [],
  drawer: false,
  staticHeaderLinks: [
    { prefetch: false, preferredPermalink: '/kontakt', title: 'Kontakt' },
    { prefetch: false, preferredPermalink: '/', title: 'Für Banken' },
    {
      prefetch: false,
      preferredPermalink: '/fuer-redaktionen',
      title: 'Für Redaktionen'
    },
    { prefetch: false, preferredPermalink: '/faq', title: 'FAQ' },
    {
      prefetch: false,
      preferredPermalink: '/wir-ueber-uns',
      title: 'Über FMH'
    }
  ]
})
const mutations = {
  updateLinks(state, data) {
    state.rawLinks = data
    state.links = data.children
  },
  orderFooterLinks(state, data) {
    const leftLinks = ['Zinsvergleiche', 'Finanzrechner']
    for (const link in data) {
      if (leftLinks.includes(data[link].title)) {
        state.leftFooterLinks.push(data[link])
      } else {
        state.rightFooterLinks.push(data[link])
      }
    }
  },
  updateDrawer(state, data) {
    state.drawer = data
  }
}
const actions = {
  async getLinks(context) {
    const data = await this.$cache(
      this.$http().header.index(),
      'navigationLinks'
    )
    context.commit('updateLinks', data)
    context.commit('orderFooterLinks', data.children)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
