import { getField, updateField } from 'vuex-map-fields'

function defaultState() {
  return {
    formParam: {
      anlageart: 0,
      anlagebetrag: null,
      //dauer: null,
      sparplanbetrag: null,
      entnahmebetrag: null,
      kontoart: 0,
      anlageschwerpunkte: 0,
      anlagestrategie: 0,
      produktpalette: 0
    },
    resultParam: {
      showResult: false
    }
  }
}

// state
const state = () => defaultState()

// getters
const getters = {
  getField,
  getFormParam() {
    return state.formParam
  },
  getResultParam() {
    return state.resultParam
  },
  getFieldValue: state => path => {
    const paths = path.split('.')
    if (paths.length === 2) {
      return state[paths[0]][paths[1]]
    } else {
      return state[paths[0]]
    }
  }
}

// actions
const actions = {
  saveParam() {
    localStorage.setItem('formParam', JSON.stringify(state.formParam))
    localStorage.setItem('resultParam', JSON.stringify(state.resultParam))
  },
  loadParam({ commit }) {
    let param = null
    if (process.browser) {
      if (localStorage.getItem('formParam')) {
        try {
          param = JSON.parse(localStorage.getItem('formParam'))

          commit('setFormParam', param)
        } catch (e) {
          localStorage.removeItem('formParam')
        }
      }
      if (localStorage.getItem('resultParam')) {
        try {
          param = JSON.parse(localStorage.getItem('resultParam'))
          commit('setResultParam', param)
        } catch (e) {
          localStorage.removeItem('resultParam')
        }
      }
    }
  }
}

// mutations
const mutations = {
  updateField,
  setFormParam(state, param) {
    state.formParam = param
  },
  setResultParam(state, param) {
    state.resultParam = param
  },
  setFieldValue(state, { path, value }) {
    const paths = path.split('.')
    if (paths.length === 2) {
      state[paths[0]][paths[1]] = value
    } else {
      state[paths[0]] = value
    }

    // this.saveParam();
    localStorage.setItem('formParam', JSON.stringify(state.formParam))
    localStorage.setItem('resultParam', JSON.stringify(state.resultParam))
  },
  resetState(state) {
    Object.assign(state, defaultState())

    localStorage.removeItem('formParam')
    localStorage.removeItem('resultParam')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
