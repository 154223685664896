<template>
  <div class="pa-5 grey lighten-3">
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="py-0 text-subtitle-2 grey--text text--darken-1"
      >
        <span class="text-subtitle-2 font-weight-medium"
          >Bewertungskriterien</span
        >
        <ul class="ml-n1">
          <li>
            <span class="font-weight-medium">Activity: </span>
            FMH-Ranking zur Einstufung der Kundeninteraktionen
          </li>
          <li>
            <span class="font-weight-medium">Performance:</span> Rendite des
            Portfolios im ausgewählten Zeitraum
          </li>
          <li>
            <span class="font-weight-medium">Gebühr:</span> Wird p. a. auf
            Depotwert berechnet, Gebühr hier nur Richtwert
          </li>
        </ul>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="py-0 text-subtitle-2 grey--text text--darken-1"
      >
        <ul class="ml-n1">
          <li>
            <span class="font-weight-medium">Rebalancing:</span> Umschichtung
            des Vermögens bei Marktveränderungen
          </li>

          <li>
            <span class="font-weight-medium">Asset Allocation:</span>
            Zusammensetzung des Portfolios
          </li>
        </ul>
      </v-col>
      <v-col cols="12" class="pb-0 text-subtitle-2 grey--text text--darken-1">
        <p>*Rechtshinweis zum Risiko der Anlage</p>
        <p>
          Investieren beinhaltet Risiken. Sie können (einen Teil) Ihre(r)
          Einlage verlieren. Wir empfehlen Ihnen nur in Finanzinstrumente zu
          investieren, die zu Ihrem Wissen und zu Ihrer Erfahrung passen.
        </p>
        <p>
          Für die Richtigkeit der Angaben kann keine Gewähr übernommen werden.
          Es wurden jedoch alle Angaben mit größter Sorgfalt aufbereitet.
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {}
</script>
