<template>
  <v-slide-group
    v-model="model"
    class="pa-0 primary no-wrap"
    center-active
    :show-arrows="false"
  >
    <v-slide-item v-for="(component, key) in components" :key="key">
      <v-card class="ma-4 pa-5" width="310" flat>
        <h2 class="text-h2 text-center primary--text text--darken-2">
          {{ component.title }}
        </h2>
        <component
          :is="'Fmh' + component.name"
          :key="key"
          :advisor="advisor"
        ></component>
      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
export default {
  props: {
    advisor: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    provider() {
      return this?.advisor?.anbieter?.anbietername
    },
    components() {
      const baseComponents = [
        { name: 'ProductCard', title: 'Produktdetails' },
        { name: 'StrategyCard', title: 'Strategie' },
        { name: 'ActivityCard', title: 'Activity' }
      ]
      if (this.advisor.anbieter.auszeichnungen.length > 0) {
        baseComponents.push({
          name: 'AwardCard',
          title: 'Auszeichnungen'
        })
      }

      baseComponents.push({
        name: 'AboutCard',
        title: 'Mehr über ' + this.advisor.anbieter.anbietername
      })
      return baseComponents
    }
  },
  data: () => ({
    model: null
  })
}
</script>

<style type="scss" scoped>
.no-wrap >>> .v-slide-group__wrapper {
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
>>> .v-slide-group__wrapper {
  touch-action: auto !important;
}
</style>
