import './src/style.css'
import './src/main.scss'
import './src/plugins/cache'
import './src/plugins/api'
import Vuetify from './src/plugins/vuetify'

const upperFirst = string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

function camelCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

function registerComponent(requireComponent, Vue, prefix = false) {
  requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName)

    // Get PascalCase name of component
    let componentName = upperFirst(
      camelCase(
        // Gets the file name regardless of folder depth
        fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, '')
      )
    )

    if (prefix) {
      componentName = upperFirst(prefix + componentName)
    }

    // Register component globally
    Vue.component(
      componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig
    )
  })
}
// function componentPath(path) {

// }

export default {
  install: (Vue, ctx = null) => {
    // const fmhComponent = componentPath('./src/components')

    const layoutComponent = require.context(
      // The relative path of the components folder
      './src/layout',
      // Whether or not to look in subfolders
      false,
      // The regular expression used to match base component filenames
      /\.(vue|js)$/
    )

    const fmhComponent = require.context(
      // The relative path of the components folder
      './src/components',
      // Whether or not to look in subfolders
      false,
      // The regular expression used to match base component filenames
      /[A-Z]\w+\.(vue|js)$/
    )
    const fmhInputComponent = require.context(
      // The relative path of the components folder
      './src/components/Input',
      // Whether or not to look in subfolders
      false,
      // The regular expression used to match base component filenames
      /[A-Z]\w+\.(vue|js)$/
    )
    const fmhCalculatorComponent = require.context(
      // The relative path of the components folder
      './src/components/Calculator',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base component filenames
      /[A-Z]\w+\.(vue|js)$/
    )
    const fmhNavigationComponent = require.context(
      // The relative path of the components folder
      './src/components/Navigation',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base component filenames
      /[A-Z]\w+\.(vue|js)$/
    )
    const fmhCommonComponent = require.context(
      // The relative path of the components folder
      './src/components/Common',
      // Whether or not to look in subfolders
      false,
      // The regular expression used to match base component filenames
      /[A-Z]\w+\.(vue|js)$/
    )
    registerComponent(fmhComponent, Vue)
    registerComponent(fmhCalculatorComponent, Vue, 'fmh')
    registerComponent(fmhNavigationComponent, Vue, 'fmh')
    registerComponent(layoutComponent, Vue, 'fmh')
    registerComponent(fmhCommonComponent, Vue, 'fmh')
    registerComponent(fmhInputComponent, Vue)

    Vue.use(Vuetify)
    if (ctx) {
      ctx.app.vuetify = Vuetify
      ctx.$vuetify = Vuetify.framework
    }
  }
}
