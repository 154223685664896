<template>
  <ValidationProvider
    v-slot="{ errors, failed }"
    mode="lazy"
    name="email"
    rules="required|email"
  >
    <AlertMessage
      v-if="failed"
      :list="false"
      class="mb-3"
      :message="errors[0]"
      type="error"
    ></AlertMessage>

    <v-text-field
      v-model="tempInput"
      outlined
      dense
      background-color="#fff"
      flat
      :hide-details="true"
      single-line
      name="email"
      :error="failed"
      label="E-Mail-Adresse eintragen..."
      @input="updateInput"
    ></v-text-field>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    input: {
      type: [Array, String, Number],
      default: () => []
    }
  },
  data() {
    return {
      tempInput: this.input
    }
  },

  methods: {
    updateInput(data) {
      this.$emit('input', data)
    }
  }
}
</script>
