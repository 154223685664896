<template>
  <v-col cols="12" sm="6" class="grey-background--hover">
    <v-avatar class="image mr-4" size="150" tile>
      <v-img :src="data.image" :alt="data.title"> </v-img>
    </v-avatar>
    <span class="caption text--secondary text-subtitle-2">
      {{ data.title }} <br />
    </span>
    <span class="h6 primary--text text-h3"> {{ data.subtitle }} <br /> </span>
    <span class="caption text--secondary text-subtitle-2">
      {{ $dateFns.format(new Date(data.date), 'dd.MM.yyyy') }} -
    </span>
    <span class="text-body-2">
      {{ data.text }}
      <FmhLink :link="data.link" link-class="navigation__link--primary"
        >... Mehr</FmhLink
      >
    </span>
  </v-col>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    }
  }
}
</script>

<style>
.image {
  float: left;
  margin-right: 10px;
}
</style>
