<template>
  <footer class="primary mt-0 pa-0">
    <v-card color="#0066A1" class="ma-0 pa-0 pb-2" tile flat width="100%">
      <background-arrow style="top: 100px"> </background-arrow>

      <v-container class="mt-0 pt-0">
        <v-row>
          <v-col cols="12" md="6" lg="6" style="z-index: 1">
            <fmh-footer-links :links="leftFooterLinks"></fmh-footer-links>
          </v-col>
          <v-col cols="12" md="6" lg="6" style="z-index: 1">
            <fmh-footer-links :links="rightFooterLinks"></fmh-footer-links>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-row
      justify="center"
      no-gutters
      class="white--text text-center text-body-2"
    >
      <v-col cols="12" class="mt-10 mb-2">
        © {{ new Date().getFullYear() }}, FMH-Finanzberatung e.K.
      </v-col>
      <v-col cols="12" class="my-3">
        <span v-for="(link, linkIndex) in footerLinks" :key="linkIndex">
          <FmhLink class="navigation__link--white text-h3" :link="link">{{
            link.title
          }}</FmhLink>

          <span
            v-if="linkIndex < footerLinks.length - 1"
            style="opacity: 50%"
            class="px-2"
          >
            |
          </span>
        </span>
      </v-col>
      <v-col cols="12" class="mt-2 text-h3"> FMH-Finanzberatung e.K. </v-col>
      <v-col cols="12" class="text-h3 pt-1">
        Zeil 13, D-60313 Frankfurt am Main
      </v-col>
      <v-col cols="12" class="text-h3 pt-1">
        Telefon 069/9518980 | Telefax 069/95189818
      </v-col>
      <v-col cols="12" class="mb-14 mt-10 text-h3">
        <v-btn outlined color="secondary darken-2" @click="scrollTop">
          Nach oben
        </v-btn>
      </v-col>
    </v-row>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      footerLinks: [
        { prefetch: false, preferredPermalink: '/kontakt', title: 'Kontakt' },
        { prefetch: false, preferredPermalink: '/', title: 'Für Banken' }, // TODO: Link URL
        {
          prefetch: false,
          preferredPermalink: '/fuer-redaktionen',
          title: 'Für Redaktionen'
        }, // TODO: Link URL
        { prefetch: false, preferredPermalink: '/faq', title: 'FAQ' },
        {
          prefetch: false,
          preferredPermalink: '/wir-ueber-uns',
          title: 'Über FMH'
        },
        {
          prefetch: false,
          preferredPermalink: '/impressum',
          title: 'Impressum'
        },
        {
          prefetch: false,
          preferredPermalink: '/agb',
          title: 'AGB'
        },
        {
          prefetch: false,
          preferredPermalink: '/datenschutz',
          title: 'Datenschutz'
        },
        {
          prefetch: false,
          preferredPermalink: '/anb',
          name: 'anb',
          title: 'ANB'
        }
      ]
    }
  },
  computed: {
    rightFooterLinks() {
      return this.$store.state.navigation.rightFooterLinks
    },
    leftFooterLinks() {
      return this.$store.state.navigation.leftFooterLinks
    }
  },
  methods: {
    scrollTop() {
      // Vuetify.goTo has issues
      window.scrollTo(0, 0)
    }
  }
}
</script>
