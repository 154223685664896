<template>
  <div class="px-5">
    <v-row>
      <v-col class="pb-2">
        <h2 class="primary--text text--darken-2">
          {{ data.title }}
        </h2>
      </v-col>
    </v-row>
    <v-row class="pt-0">
      <v-col
        v-for="(item, tIndex) in data.summary"
        :key="tIndex"
        class="pt-0 mt-0"
        cols="12"
        md="4"
      >
        <v-img
          v-if="item.image"
          :aspect-ratio="16 / 9"
          :src="item.image"
          :alt="item.alt || item.title"
          :title="item.title || item.title"
        ></v-img>
        <ul v-else>
          <li class="py-1" v-if="item.title">
            <h3 class="primary--text">
              {{ item.title }}
            </h3>
          </li>
          <FmhLink v-if="item.button" :link="item.button">
            <v-btn
              class="px-10 my-3 mr-10"
              color="secondary darken-1"
              width="250"
              depressed
              :block="$vuetify.breakpoint.xsOnly"
            >
              {{ item.button.title }}
            </v-btn>
          </FmhLink>

          <span v-else-if="item.text">{{ item.text }}</span>
          <li>
            <ul v-if="item.list">
              <li
                v-for="(list, idx) in item.list"
                :key="idx"
                class="px-1 pt-1 pb-2"
              >
                <FmhLink :link="list" link-class="navigation__link--primary">
                  <v-icon size="19" color="primary">mdi-{{ list.icon }}</v-icon>
                  {{ list.title }}
                </FmhLink>
              </li>
            </ul>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    }
  }
}
</script>

<style scoped>
ul {
  list-style-type: none;
  padding-left: 0 !important;
}

/* .theme--light.v-sheet--outlined {
  border-style: thin;
  border-color: #42a5f5;
} */

p {
  white-space: pre-line;
}
</style>
