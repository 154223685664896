<template>
  <v-card flat rounded="0" class="grey-background">
    <v-container class="pb-sm-12 pb-7 pt-0">
      <v-row class="pt-0 mt-0">
        <v-col cols="12" class="text-center py-0 mt-0">
          <h2 class="text-h2">
            <v-icon class="large-newsletter__icon mb-3" color="black">
              mdi-send-outline
            </v-icon>
            <br />
            Mit dem FMH Newsletter bestens informiert!
          </h2>
          <p class="text-body-2 pt-1">
            Abonnieren Sie unseren Newsletter mit transparenten
            Finanzmarktanalysen und unabhängiger Beratung. Sie können sich
            jederzeit wieder abmelden, indem Sie auf den Link zur Abmeldung im
            Newsletter klicken. Weitere Informationen finden Sie in
            <nuxt-link
              class="text-decoration-underline default-text-color"
              to="/datenschutz"
              >unseren Datenschutzrichtlinien</nuxt-link
            >.
          </p>
        </v-col>
        <v-col
          sm="6"
          md="4"
          class="justify-center pt-1 mt-0"
          offset-sm="3"
          offset-md="4"
        >
          <ValidationObserver ref="form">
            <form @submit.prevent="sendEmail">
              <EmailNewsletterTextField v-model="email">
              </EmailNewsletterTextField>

              <v-btn
                color="secondary darken-1"
                @click="sendEmail"
                block
                outlined
                class="mt-5"
              >
                JETZT ANMELDEN</v-btn
              >
            </form>
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      registered: false,
      loading: false
    }
  },
  methods: {
    async sendEmail() {
      const validation = await this.$refs.form.validate()
      if (!validation) {
        return null
      }
      const data = {
        email: this.email,
        tags: ['Large Newsletter', 'landingpage']
      }

      this.loading = true
      const result = await this.$http().user.newsletter(data)
      this.loading = false

      if (result) {
        this.email = ''
        this.registrated = true
      }
    }
  }
}
</script>

<style scoped>
.large-newsletter__icon {
  font-size: 96px;
  transform: rotate(-30deg);
}
</style>
