<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on"> mdi-share-variant </v-icon>
    </template>
    <v-card>
      <v-card-title>
        <span class="title font-weight-bold">Teilen</span>
        <v-spacer></v-spacer>
        <v-btn class="mx-0" icon @click="dialog = false">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-icon color="indigo">
              mdi-facebook
            </v-icon>
          </v-list-item-action>
          <v-card-title>Facebook</v-card-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-icon color="cyan"> mdi-twitter </v-icon>
          </v-list-item-action>
          <v-card-title>Twitter</v-card-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-email</v-icon>
          </v-list-item-action>
          <v-card-title>Email</v-card-title>
        </v-list-item>
      </v-list>
      <v-text-field
        ref="link"
        :label="copied ? 'Link kopiert' : 'Link kopieren'"
        class="pa-4"
        readonly
        :value="link"
        @click="copy"
      ></v-text-field>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      copied: false,
      dialog: false
    }
  },
  props: {
    link: {
      type: String,
      default: 'www.fmh.de'
    }
  },
  methods: {
    copy() {
      const markup = this.$refs.link
      markup.focus()
      document.execCommand('selectAll', false, null)
      this.copied = document.execCommand('copy')
    }
  }
}
</script>
