<template>
  <div class="advantages__container">
    <v-row class="mt-2 pb-sm-6">
      <v-col cols="12" sm="6" class="pr-sm-16">
        <h4 class="secondary--text text--lighten-1 text-h1">
          {{ data.title }}
        </h4>
        <h3 class="white--text text-h2">
          {{ data.subtitle }}
        </h3>
        <h4 class="white--text text-subtitle-1 mt-6 mb-2">
          {{ data.headline }}
        </h4>
        <p class="text-body-1 white--text">
          {{ data.text }}
        </p>
      </v-col>
      <v-col cols="12" sm="6">
        <h3 class="white--text text-subtitle-1">
          {{ data.advantagesTitle }}
        </h3>
        <ul>
          <li
            v-for="(item, idx) in data.advantages"
            :key="idx"
            class="px-1 py-1"
          >
            <span class="white--text text-subtitle-1">
              <v-icon color="secondary lighten-1 ">mdi-check</v-icon>
              {{ item.title }}
            </span>
          </li>
        </ul>
        <div class="col-sm-8">
          <FmhLink :link="data.button">
            <v-btn
              depressed
              block
              class="secondary darken-1 mt-3 d-flex"
              color="white--text"
            >
              {{ data.button.text }}
            </v-btn>
          </FmhLink>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    }
  }
}
</script>

<style scoped>
.advantages__container {
  background-image: url('/images/document-check.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 240px 240px;
}

ul {
  list-style-type: none;
  padding-left: 0 !important;
}
</style>
