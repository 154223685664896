var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"secondary darken-4","elevation":"7"}},[_c('v-card-text',[_c('form',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validateRegistration($event)}}},[(!_vm.registrated)?_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formErrors = ref.errors;
var formFailure = ref.failed;
return [_c('h3',{staticClass:"text-h2 text-center white--text pb-5 pt-2"},[_vm._v(" Jetzt registrieren! ")]),(formFailure)?_c('AlertMessage',{attrs:{"list":true,"message":formErrors,"type":"error"}}):_vm._e(),_c('ValidationProvider',{attrs:{"mode":"lazy","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('label',{staticClass:"text-body-2 white--text",attrs:{"for":"email"}},[_vm._v(" E-Mail-Adresse * ")]),_c('v-text-field',{attrs:{"id":"email","outlined":"","background-color":"white","dense":"","error":failed,"name":"email","placeholder":_vm.emailPlaceholder},on:{"focus":function($event){_vm.emailPlaceholder = ''}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"mode":"lazy","name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('label',{staticClass:"text-body-2 white--text",attrs:{"for":"password"}},[_vm._v("Passwort *")]),_c('v-text-field',{attrs:{"id":"password","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"outlined":"","background-color":"white","dense":"","error":failed,"name":"password","placeholder":_vm.passwordPlaceholder},on:{"focus":function($event){_vm.passwordPlaceholder = ''},"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"mode":"lazy","name":"confirmation","rules":"required|confirmation:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('label',{staticClass:"text-body-2 white--text",attrs:{"for":"confirmation"}},[_vm._v("Passwort Wiederholung * ")]),_c('v-text-field',{attrs:{"id":"confirmation","append-icon":_vm.showConfirmation ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirmation ? 'text' : 'password',"outlined":"","background-color":"white","dense":"","error":failed,"name":"confirmation","placeholder":_vm.confirmationPlaceholder},on:{"focus":function($event){_vm.confirmationPlaceholder = ''},"click:append":function($event){_vm.showConfirmation = !_vm.showConfirmation}},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"mode":"lazy","name":"privacy","rules":"checked"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"2","xs":"1","sm":"1"}},[_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"id":"privacy","dark":"","error":failed,"name":"primary"},model:{value:(_vm.privacy),callback:function ($$v) {_vm.privacy=$$v},expression:"privacy"}})],1),_c('v-col',{attrs:{"cols":"10","xs":"1","sm":"11"}},[_c('span',{staticClass:"text-body-2 white--text-light",staticStyle:{"cursor":"pointer"},attrs:{"slot":"label"},on:{"click":function($event){_vm.privacy = !_vm.privacy}},slot:"label"},[_vm._v(" Bitte bestätigen Sie, dass Sie die "),_c('label',{attrs:{"for":"privacy"}},[_c('a',{staticClass:"navigation__link--white",attrs:{"href":"/datenschutz"}},[_vm._v("Datenschutzrichtlinien")])]),_vm._v(" der FMH-Finanzberatung gelesen haben und diese akzeptieren. ")])])],1)]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","offset":"0","offset-lg":"3"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"block":"","color":"secondary darken-1"},on:{"click":_vm.validateRegistration}},[_vm._v("Registrieren ")])],1)],1),_c('p',{staticClass:"white--text-light"},[_vm._v("* Pflichtfelder")])]}}],null,false,922182789)})],1):_c('div',[_c('AlertMessage',{attrs:{"type":"success","list":false}},[_vm._v(" Sie haben sich erfolgreich registriert. "),_c('br'),_vm._v(" Bitte schauen Sie in Ihr Postfach und bestätigen Sie unsere E-Mail. ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }