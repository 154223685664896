<template>
  <v-icon size="30" class="orange--text"
    >mdi-menu-down {{ sorting ? 'mdi-rotate-180' : '' }}</v-icon
  >
</template>

<script>
export default {
  props: {
    sorting: {
      type: Boolean,
      default: false
    }
  }
}
</script>
