<template>
  <div>
    <v-container class="px-0 mx-0 hidden-md-and-up pb-0">
      <v-slide-group v-model="imageSlider" class="px-0" :show-arrows="false">
        <v-slide-item
          v-for="(item, iIndex) in images"
          :key="iIndex"
          class="mx-3"
        >
          <v-card
            :id="iIndex"
            v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }"
            flat
            width="50vw"
          >
            <nuxt-link :to="item.to">
              <v-img :src="item.image" :aspect-ratio="4 / 3" alt="fmh image">
              </v-img>
            </nuxt-link>
          </v-card>
        </v-slide-item>
      </v-slide-group>
      <v-layout class="justify-center hidden-md-and-up">
        <v-item-group class="shrink mr-6" mandatory tag="v-flex">
          <v-item v-for="(image, iIndex) in images" :key="iIndex">
            <v-btn
              icon
              small
              :color="
                imageSliderLevel == iIndex ? 'secondary darken-1' : 'grey '
              "
              @click="imageSlider = iIndex"
            >
              <v-icon small>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
      </v-layout>
    </v-container>
    <v-container class="hidden-sm-and-down">
      <v-row
        ><v-col v-for="(item, iIndex) in images" :key="iIndex" cols="4">
          <nuxt-link :to="item.to">
            <v-card flat>
              <v-img
                :src="item.image"
                :aspect-ratio="4 / 3"
                alt="fmh image"
              ></v-img>
            </v-card>
          </nuxt-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => [
        {
          to: '/',
          image:
            'https://images.pexels.com/photos/669610/pexels-photo-669610.jpeg?auto=compress&cs=tinysrgb&h=650&w=940'
        },
        {
          to: '/',
          image:
            'https://images.pexels.com/photos/669610/pexels-photo-669610.jpeg?auto=compress&cs=tinysrgb&h=650&w=940'
        },
        {
          to: '/',
          image:
            'https://images.pexels.com/photos/669610/pexels-photo-669610.jpeg?auto=compress&cs=tinysrgb&h=650&w=940'
        }
      ]
    }
  },
  data() {
    return {
      imageSlider: 1,
      imageSliderLevel: 1,
      slider: ''
    }
  },
  methods: {
    onIntersect(entries) {
      if (entries[0].intersectionRatio >= 0.5) {
        this.imageSliderLevel = entries[0].target.id
      }
    }
  }
}
</script>
