<template>
  <v-chip label color="white">
    <v-icon left :color="icon === 'check' ? 'green' : 'red'">
      mdi-{{ icon }}-circle </v-icon
    ><slot></slot
  ></v-chip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'check'
    }
  }
}
</script>
