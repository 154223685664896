<template>
  <v-row class="pb-0">
    <v-col cols="12" class="py-0">
      <span class="grey--text text--darken-2 pb-0 text-body-3">
        {{ $dateFns.format(new Date(data.date), 'dd.MM.yyyy') }}
        -
        {{ data.title }}
      </span>
      <p class="primary--text text-h3 pt-0 mt-0">
        {{ data.subtitle }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    }
  }
}
</script>
