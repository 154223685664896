<template>
  <v-row class="mt-0">
    <v-col cols="12" md="2" class="ml-md-3 text-h3 gray--text"
      ><slot name="header"></slot
    ></v-col>
    <v-col cols="12" md="8" class="gray lighten-1 pl-2 pl-md-0"
      ><slot name="element"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>
