<template>
  <v-row>
    <v-col cols="12">
      <h3 class="grey--text text--darken-2 text-body-2">
        {{ data.title }}
      </h3>
      <h3 class="primary--text text-subtitle-1">
        {{ data.subtitle }}
      </h3>
      <p class="text-body-2">
        {{ data.text }}
        <FmhLink :link="data.link" link-class="navigation__link--primary"
          >... Mehr</FmhLink
        >
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
      default: () => {}
    }
  }
}
</script>
