<template>
  <v-card
    v-bind="$attrs"
    :disabled="disabled"
    :class="disabled ? 'card--disabled' : ''"
    v-on="$listeners"
    elevation="0"
  >
    <v-container class="pt-0 px-4">
      <v-row class="mb-0 pb-0">
        <v-col
          md="3"
          sm="4"
          cols="12"
          class="title text-subtitle-1 pr-0 mb-0 pb-0"
        >
          <div>
            <slot name="name" />
          </div>
        </v-col>
        <v-col
          v-if="!disabled"
          md="6"
          sm="4"
          cols="12"
          class="text-center text-body-2 mt-2 mb-0 pb-0"
        >
          <slot name="description" />
        </v-col>
      </v-row>
      <v-row v-if="!disabled && hasBodySlot" class="pa-0">
        <v-col md="12" class="pa-0 ma-0">
          <div style="text-align: center" class="grey--text mb-0">
            <slot name="body" />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!disabled && hasFooterSlot">
        <v-col md="10" offset-md="1" class="text-subtitle-2 pt-0 pb-1">
          <div style="text-align: center" class="gray--text">
            <slot name="footer" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    windowWidth: {
      type: Number,
      default: 1000
    }
  },

  computed: {
    hasBodySlot() {
      return !!this.$slots.body
    },

    hasFooterSlot() {
      return !!this.$slots.footer
    }
  }
}
</script>

<style type="text/css" scoped>
.card--disabled {
  background: #f2f2f2;
  color: #bcbcbc;
}

@media (max-width: 600px) {
  .title {
    text-align: center;
  }
}
</style>
