'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true
})
exports.default = null

const primary = {
  base: '#085191',
  lighten1: '#2EAFED',
  lighten2: '#50BDF0',
  lighten3: '#80D0F5',
  lighten4: '#B2E2F9',
  lighten5: '#E1F4FD',
  darken1: '#13A2EA',
  darken2: '#1194DB',
  darken3: '#0E81C7',
  darken4: '#0C71B3'
}

const secondary = {
  base: '#1194DB',
  lighten1: '#5FD5FF',
  darken1: '#13A2EA',
  darken2: '#13A2EC',
  darken4: '#0066A1'
}

const gray = {
  base: '#181818',
  lighten1: '#F2F2F2'
}

const error = '#B00020'
const red = {
  base: '#D02100'
}

const success = '#4CAF50'

const _default = Object.freeze({
  primary,
  secondary,
  error,
  success,
  gray,
  red
})

exports.default = _default
