<template>
  <v-card class="robocard white--text"><slot /></v-card>
</template>

<script>
export default {}
</script>

<style type="text/css" scoped>
.robocard {
  background: rgb(63, 149, 218);
  background: linear-gradient(#1194db 38%, #2b6fab 65%, #0066a1 90%);
}
</style>
