export const settings = {
  euroZeichen: 'EUR',
  prozentZeichen: '%',
  datepicker: {
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ],
    dayNames: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    today: new Date(),
    minDate: new Date(2010, 0, 1),
    maxDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
  }
}
export default {
  methods: {}
}
