<template>
  <div>
    <fmh-detail-double-cols class="py-0 px-3 white-divider">
      <template #header><h3 class="text-h3">Produktname</h3></template>
      <template #element>
        <p>{{ advisor.produktDetails.produktname }}</p>
      </template>
    </fmh-detail-double-cols>
    <fmh-detail-triple-cols
      class="py-0 px-3  white-divider"
      :class="{ 'white-divider': valueExists(sonderaktionBemerkung) }"
    >
      <template #header><h3 class="text-h3">Gebühren</h3></template>
      <template #first-element>
        <fmh-detail-list :list="fees"></fmh-detail-list>
      </template>
      <template #second-element>
        <fmh-detail-list :list="stragies"></fmh-detail-list>
      </template>
    </fmh-detail-triple-cols>

    <fmh-detail-double-cols
      class="py-0 px-3 white-divider"
      :class="{ 'white-divider': valueExists(besonderheiten) }"
      v-if="valueExists(sonderaktionBemerkung)"
    >
      <template #header><h3 class="text-h3">Prämien</h3></template>
      <template #element>
        <v-chip label small class="gray mr-3" dark>
          <b>Neukunden-Aktion</b></v-chip
        >

        {{
          advisor && valueExists(sonderaktionBemerkung)
            ? sonderaktionBemerkung
            : 'Keine'
        }}
      </template>
    </fmh-detail-double-cols>
    <fmh-detail-double-cols
      class="pa-3 pb-7"
      v-if="valueExists(besonderheiten)"
    >
      <template #header><h3 class="text-h3">Besonderheit</h3></template>
      <template #element>
        <span v-for="(text, key) in besonderheiten" :key="key">
          {{ text }}
        </span>
      </template>
    </fmh-detail-double-cols>
  </div>
</template>

<script>
import FmhDetailList from '../../Common/DetailList.vue'
import FmhDetailTripleCols from '../../Common/DetailTripleCols.vue'
export default {
  components: { FmhDetailTripleCols, FmhDetailList },
  props: {
    advisor: {
      type: Object,
      default: null
    }
  },
  methods: {
    getGebuehren(gebuehren) {
      let gebuehrenString = ''
      gebuehren.map(key => {
        gebuehrenString +=
          'ab ' +
          key.abDepotwert.toLocaleString() +
          ' €: ' +
          key.gebuehr.toLocaleString() +
          '% <br/>'
      })
      return gebuehrenString
    },
    valueExists(data) {
      return data.length > 0
    }
  },
  computed: {
    sonderaktionBemerkung() {
      return this.advisor.produktDetails.sonderaktionBemerkung
    },
    besonderheiten() {
      return this.advisor.produktDetails.besonderheiten
    },
    fees() {
      const arr = []

      if (this.advisor) {
        const fees =
          this.advisor.produktDetails.gebuehren.length === 0
            ? 'Keine'
            : this.getGebuehren(this.advisor.produktDetails.gebuehren)
        // console.log(this.advisor.produktDetails.gebuehren)
        arr.push({
          title: 'Mindestsparrate',
          description:
            this.advisor.produktDetails.mindestsparrate.toLocaleString() + ' €'
        })
        arr.push({
          title: 'Mindestanlage',
          description:
            this.advisor.produktDetails.mindestanlage.toLocaleString() + ' €'
        })
        arr.push({
          title: 'Gebühren',
          description: fees.toLocaleString()
        })
      }
      return arr
    },
    stragies() {
      const arr = []
      if (this.advisor) {
        const changeFee =
          this.advisor.produktDetails.risikoprofilAenderungGebuehr === 0
            ? 'Kostenfrei'
            : this.advisor.produktDetails.risikoprofilAenderungGebuehr + ' €'
        const performanceFee =
          this.advisor.produktDetails.performanceGebuehr === 0
            ? 'Keine'
            : this.advisor.produktDetails.performanceGebuehr + ' €'
        arr.push({
          title: 'Strategie wechseln',
          description: changeFee
        })
        arr.push({
          title: 'Performancegebühr',
          description: performanceFee
        })
      }
      return arr
    }
  }
}
</script>
