<template>
  <v-text-field
    v-bind="$attrs"
    class="textbox font-weight-medium"
    v-on="$listeners"
    :type="type"
    ><slot
  /></v-text-field>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style type="text/css" scoped>
.textbox >>> input {
  text-align: right;
  padding-top: 0;
  padding-bottom: 0;
}

.textbox >>> .v-input__control {
  height: 50px;
}

.textbox >>> fieldset {
  height: 43px;
}

.textbox >>> .v-text-field__slot {
  height: 43px;
}
</style>
