<template>
  <v-card height="100%">
    <v-img
      :src="person.image"
      :alt="`fhm experten - ${person.name}`"
      :title="`fhm experten - ${person.name}`"
    ></v-img>
    <v-card-text class="text--primary">
      <span class="grey--text text--darken-2 text-body-3">
        {{ person.genre }}
      </span>
      <h3 class="primary--text text-h2">
        {{ person.name }}
      </h3>
      <p class="text-body-2 pt-3">
        {{ person.description }}
      </p>
      <v-row class="justify-end">
        <v-col cols="12" class="py-0">
          <h4 class="text-body-2">{{ person.telephone }}</h4>
        </v-col>
        <v-col cols="12" class="py-0">
          <a :href="`mailto:${person.email}`"> {{ person.email }}</a>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    person: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
