import param from './modules/param'
import result from './modules/result'
import navigation from './modules/navigation'
import roboAdvisor from './modules/roboAdvisor'
export default {
  param,
  result,
  navigation,
  roboAdvisor
}
